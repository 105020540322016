import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Contact }          from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Flow Sim Restart Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimRestart(props) {
    const { open, onClose, onSubmit } = props;

    const { elem, parentFlows } = Store.useState("flow");
    const { simulationID } = Store.useState("flowSimulation");
    const { restartSimulation } = Store.useAction("flowSimulation");


    // The Initial Data
    const initialData = {
        simulationID        : 0,
        resolveConversation : 1,
        deleteConversation  : 0,
        sameFlow            : 1,
        flowID              : 0,
        sameContact         : 1,
        deleteContact       : 0,
        newContact          : 1,
        fullName            : "",
        useCurrentLanguage  : 0,
        contactID           : 0,
        contactName         : "",
        initialMessage      : 1,
        message             : "",
    };


    // Initializes the Data
    React.useEffect(() => {
        setElem({ ...initialData, simulationID });
    }, [ open ]);

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("flow", initialData, restartSimulation, onSubmit);

    // Load the Data
    const { loading } = useDialog("flow", open, 0, { clientID : elem.clientID });



    // Do the Render
    return <EditDialog
        open={open}
        icon="simulate"
        title="FLOWS_SIMULATION_RESTART_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="toggle"
            name="resolveConversation"
            label="CONVERSATIONS_RESOLVE_TITLE"
            value={!!data.resolveConversation}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="deleteConversation"
            label="CONVERSATIONS_DELETE_TITLE"
            value={!!data.deleteConversation}
            onChange={handleChange}
            withBorder
        />

        <InputField
            type="toggle"
            name="sameFlow"
            label="FLOWS_SIMULATION_SAME_FLOW"
            value={!!data.sameFlow}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.sameFlow}
            type="select"
            name="flowID"
            label="FLOWS_SIMULATION_START_FLOW"
            value={data.flowID}
            error={errors.flowID}
            options={parentFlows}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="sameContact"
            label="FLOWS_SIMULATION_SAME_CONTACT"
            value={!!data.sameContact}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.sameContact}
            type="toggle"
            name="deleteContact"
            label="CONTACTS_DELETE_TITLE"
            value={!!data.deleteContact}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.sameContact}
            type="toggle"
            name="newContact"
            label="FLOWS_SIMULATION_NEW_CONTACT"
            value={!!data.newContact}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.sameContact || !data.newContact}
            name="fullName"
            label="FLOWS_SIMULATION_CONTACT_NAME"
            value={data.fullName}
            error={errors.fullName}
            onChange={handleChange}
        />
        <InputField
            isHidden={!!data.sameContact || !data.newContact}
            type="toggle"
            name="useCurrentLanguage"
            label="FLOWS_SIMULATION_USE_CURRENT_LANGUAGE"
            value={data.useCurrentLanguage}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.sameContact || !!data.newContact}
            type="suggest"
            name="contactName"
            label="CONTACTS_SINGULAR"
            suggestID="contactID"
            suggestFetch={Contact.search}
            suggestParams={{ clientID : elem.clientID }}
            value={data.contactName}
            error={errors.contactID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="initialMessage"
            label="FLOWS_SIMULATION_INITIAL_MESSAGE"
            value={!!data.initialMessage}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!data.initialMessage}
            type="textarea"
            name="message"
            label="FLOWS_SIMULATION_MESSAGE"
            value={data.message}
            error={errors.message}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimRestart.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default FlowSimRestart;
