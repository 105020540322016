import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Components
import FlowEdit             from "../Dialogs/FlowEdit";
import FlowSimStart         from "../Simulation/FlowSimStart";

// Dashboard
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Flow Dialogs
 * @returns {React.ReactElement}
 */
function FlowDialogs() {
    const { flowID, flowTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("flow");
    const { fetchElem, publishFlow, clearFlow, deleteFlow } = Store.useAction("flow");

    const { totalChanges, publishErrors } = Store.useState("flowEditor");
    const { fetchEditor } = Store.useAction("flowEditor");

    const { action } = Store.useState("flowState");
    const { setAction, setInPublish, setDetailsTab } = Store.useAction("flowState");


    // Handles the Edit
    const handleEdit = (response) => {
        setAction();
        if (response.flowID !== elem.id) {
            navigate("SETTINGS", "FLOWS", response.flowID, "EDIT");
        } else {
            fetchElem(elem.id);
        }
    };

    // Handles the Simulate
    const handleSimulate = () => {
        setAction();
        if (!Navigate.isUrl("EDIT", flowTab)) {
            navigate("SETTINGS", "FLOWS", flowID, "EDIT");
        }
        setDetailsTab("simulation");
        openDetails();
    };

    // Handles the Publish
    const handlePublish = async () => {
        setAction();
        if (elem.needsPublish) {
            const result = await publishFlow(elem.id);
            if (result.success) {
                setInPublish(false);
                fetchEditor(elem.id);
            }
        }
    };

    // Handles the Clear
    const handleClear = async () => {
        setAction();
        const result = await clearFlow(elem.id);
        if (result.success) {
            fetchEditor(elem.id);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        setAction();
        const result = await deleteFlow(elem.id);
        if (result.success) {
            navigate("SETTINGS", "FLOWS");
        }
    };

    // Handles the Errors
    const handleErrors = () => {
        setAction();
        if (!Navigate.isUrl("EDIT", flowTab)) {
            navigate("SETTINGS", "FLOWS", flowID, "EDIT");
        } else {
            openDetails();
        }
        setDetailsTab("editor");
    };

    // Publish Text
    const publishText = React.useMemo(() => {
        if (!elem.needsPublish) {
            return NLS.format("FLOWS_PUBLISH_NO_CHANGES", elem.name);
        }
        if (totalChanges > 0) {
            return NLS.pluralize("FLOWS_PUBLISH_CHANGES", totalChanges, elem.name);
        }
        return NLS.format("FLOWS_PUBLISH_CHANGES", elem.name);
    }, [ elem.name, elem.needsPublish, totalChanges ]);


    // Variables
    const totalPublishErrors = publishErrors?.length || 0;


    // Do the Render
    return <>
        <FlowEdit
            open={action.isEdit || action.isCopy}
            isCopy={action.isCopy}
            elemID={elem.id}
            clientID={elem.clientID}
            onSubmit={handleEdit}
            onClose={setAction}
        />
        <FlowSimStart
            open={action.isSimulate}
            onSubmit={handleSimulate}
            onClose={setAction}
        />

        <ConfirmDialog
            open={action.isPublish}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={publishText}
            onSubmit={handlePublish}
            onClose={setAction}
        />
        <ConfirmDialog
            open={action.isClear}
            icon="clear"
            title="GENERAL_CLEAR_CHANGES"
            message="FLOWS_CLEAR_CHANGES"
            primary="GENERAL_CLEAR"
            content={elem.name}
            onSubmit={handleClear}
            onClose={setAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="FLOWS_DELETE_TITLE"
            message="FLOWS_DELETE_TEXT"
            content={elem.name}
            onSubmit={handleDelete}
            onClose={setAction}
        />

        <AlertDialog
            open={action.isError}
            icon="publish"
            title="GENERAL_PUBLISH_CHANGES"
            message={totalPublishErrors > 1 ? "FLOWS_ERRORS_TEXT" : "FLOWS_ERROR_TEXT"}
            content={String(totalPublishErrors)}
            onClose={handleErrors}
        />
    </>;
}

export default FlowDialogs;
