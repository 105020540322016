import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { EmailSignature }   from "Utils/API";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
};



// The Actions
const actions = {
    /**
     * Fetches the current Credential's Email Signatures
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchList(dispatch) {
        dispatch({ type : "EMAIL_SIGNATURE_LOADING" });
        const data = await EmailSignature.getAll();
        dispatch({ type : "EMAIL_SIGNATURE_LIST", data });
        return data;
    },

    /**
     * Edits an Email Signature
     * @param {Function} dispatch
     * @param {Number}   userID
     * @param {String}   emailSignature
     * @returns {Promise}
     */
    editEmailSignature(dispatch, userID, emailSignature) {
        return EmailSignature.edit({ userID, emailSignature });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "EMAIL_SIGNATURE_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "EMAIL_SIGNATURE_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "EMAIL_SIGNATURE_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            edition   : state.edition + 1,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
