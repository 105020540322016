import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";

// Components
import ConversationFilter   from "../Actions/ConversationFilter";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.header`
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    overflow: auto;
`;

const Tabs = Styled(TabList)`
    flex-grow: 2;

    .tabs-content {
        width: 100%;
        gap: 8px;
    }
    .tab-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        padding: 6px;
    }
    .tab-amount {
        font-size: 10px;
    }
`;

const HeaderIcon = Styled(IconLink)`
    --link-size: 30px;
    --link-font: 18px;

    ${(props) => props.variant === "light" && `
        background-color: var(--lighter-gray);
    `}
    ${(props) => props.variant === "dark" && `
        background-color: var(--primary-color);
    `}
    ${(props) => props.isDisabled && `
        --link-background: var(--lighter-gray);
    `}
`;



/**
 * The List Header
 * @returns {React.ReactElement}
 */
function ListHeader() {
    const { isAnyAdmin } = Store.useState("auth");
    const { stats, filters, sort, showSearch } = Store.useState("conversation");
    const { startSearch, endSearch } = Store.useAction("conversation");

    const { conversationHash } = Navigate.useParams();
    const conversationsNavigate = Conversations.useGoto();


    // The Current State
    const [ showFilter, setShowFilter ] = React.useState(false);

    // The Initial Filters
    const initialFilter = {
        period        : 0,
        fromDate      : "",
        toDate        : "",
        order         : "lastMsgDesc",
        providerName  : [],
        teamID        : [],
        tagID         : [],
        tongueID      : [],
        userID        : [],
        qualification : [],
        onlyMine      : 0,
    };


    // Handles the Tab
    const handleTab = (action, status) => {
        if (showSearch) {
            endSearch();
        }
        const filter = Conversations.statusToUrl(status, isAnyAdmin);
        conversationsNavigate(filter, conversationHash);
    };

    // Shows the Search
    const handleShowSearch = () => {
        if (!showSearch) {
            startSearch();
        } else {
            endSearch();
        }
    };


    // Do the Render
    const hasFilter = !Utils.isEmpty(filters) && !Utils.areObjectsEqual(initialFilter, filters);

    return <>
        <Container className="no-scrollbars">
            <Tabs
                selected={showSearch ? 0 : sort.filter}
                onAction={handleTab}
            >
                {Object.values(stats).map((elem) => <TabItem
                    isHidden={elem.key === "Flow" && !isAnyAdmin}
                    key={elem.key}
                    icon={elem.icon}
                    value={elem.key}
                    tooltip={elem.name}
                    tooltipVariant="top"
                    amount={elem.key !== "Resolved" ? elem.total : undefined}
                    badge={elem.key !== "Resolved" && !isAnyAdmin ? elem.unread : 0}
                />)}
            </Tabs>

            <HeaderIcon
                variant="light"
                icon="filter"
                tooltip="GENERAL_FILTER"
                tooltipVariant="top"
                onClick={() => setShowFilter(true)}
                withMark={!showSearch && hasFilter}
                isDisabled={showSearch}
            />
            <HeaderIcon
                variant={showSearch ? "dark" : "light"}
                icon="search"
                tooltip="GENERAL_SEARCH"
                tooltipVariant="top"
                onClick={handleShowSearch}
            />
        </Container>

        <ConversationFilter
            open={showFilter}
            initialData={initialFilter}
            onClose={() => setShowFilter(false)}
        />
    </>;
}

export default ListHeader;
