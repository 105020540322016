import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";



// The initial State
const initialState = {
    action         : Action.get(),
    position       : 0,
    forTrigger     : false,

    hasChanges     : false,
    inActivate     : false,
    inPublish      : false,
    viewErrors     : false,

    toSelectAction : 0,
    selectedAction : 0,
};



// The Actions
const actions = {
    /**
     * Sets the Integration action
     * @param {Function} dispatch
     * @param {String}   action
     * @returns {Void}
     */
    setAction(dispatch, action) {
        dispatch({ type : "INTEGRATION_ACTION", action });
    },

    /**
     * Sets the Integration Create
     * @param {Function} dispatch
     * @param {Number}   position
     * @param {Boolean=} forTrigger
     * @returns {Void}
     */
    setCreate(dispatch, position, forTrigger = false) {
        dispatch({ type : "INTEGRATION_CREATE", position, forTrigger });
    },

    /**
     * Sets the Integration has changes
     * @param {Function} dispatch
     * @param {Boolean}  hasChanges
     * @returns {Void}
     */
    setHasChanges(dispatch, hasChanges) {
        dispatch({ type : "INTEGRATION_HAS_CHANGES", hasChanges });
    },

    /**
     * Sets the Integration in Activate mode
     * @param {Function} dispatch
     * @param {Boolean}  inActivate
     * @returns {Void}
     */
    setInActivate(dispatch, inActivate) {
        dispatch({ type : "INTEGRATION_IN_ACTIVATE", inActivate });
    },

    /**
     * Sets the Integration in Publish mode
     * @param {Function} dispatch
     * @param {Boolean}  inPublish
     * @returns {Void}
     */
    setInPublish(dispatch, inPublish) {
        dispatch({ type : "INTEGRATION_IN_PUBLISH", inPublish });
    },

    /**
     * Sets the to select Integration Action
     * @param {Function} dispatch
     * @param {Number}   toSelectAction
     * @returns {Void}
     */
    setToSelectAction(dispatch, toSelectAction) {
        dispatch({ type : "INTEGRATION_SELECT_ACTION", toSelectAction });
    },

    /**
     * Sets the selected Integration Action
     * @param {Function} dispatch
     * @param {Number}   selectedAction
     * @returns {Void}
     */
    setSelectedAction(dispatch, selectedAction) {
        dispatch({ type : "INTEGRATION_SELECTED_ACTION", selectedAction });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "INTEGRATION_ACTION":
        return {
            ...state,
            action         : Action.get(action.action),
        };

    case "INTEGRATION_CREATE":
        return {
            ...state,
            action         : Action.get("CREATE"),
            position       : action.position,
            forTrigger     : action.forTrigger,
        };

    case "INTEGRATION_HAS_CHANGES":
        return {
            ...state,
            hasChanges     : action.hasChanges,
        };

    case "INTEGRATION_IN_ACTIVATE":
        return {
            ...state,
            inActivate     : action.inActivate,
            viewErrors     : action.inActivate,
            action         : action.inActivate ? Action.get("ERROR") : Action.get(),
        };

    case "INTEGRATION_IN_PUBLISH":
        return {
            ...state,
            inPublish      : action.inPublish,
            viewErrors     : action.inPublish,
            action         : action.inPublish ? Action.get("ERROR") : Action.get(),
        };

    case "INTEGRATION_SELECT_ACTION":
        return {
            ...state,
            toSelectAction : action.toSelectAction || 0,
        };

    case "INTEGRATION_SELECTED_ACTION":
        return {
            ...state,
            selectedAction : action.selectedAction || 0,
        };

    case "INTEGRATION_STATE_RESET":
        return {
            ...state,
            toSelectAction : 0,
            selectedAction : 0,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
