import React                from "react";
import Styled               from "styled-components";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.div`
    flex-grow: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 24px;
    max-width: 700px;
`;

const Item = Styled.div.attrs(({ isSelected }) => ({ isSelected }))`
    box-sizing: border-box;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        border-color: var(--border-color-dark);
    }
    ${(props) => props.isSelected && "border-color: var(--border-color-dark);"}
`;

const Title = Styled.h3`
    margin: 0 0 8px 0;
    color: var(--title-color);
    font-family: var(--title-font);
    font-weight: 600;
`;

const Subtitle = Styled.p`
    margin: 0 0 8px 0;
`;

const Current = Styled.p`
    color: var(--success-color);
`;

const Active = Styled.p`
    color: var(--font-light);
`;

const Pending = Styled.p`
    color: var(--warning-color);
`;



/**
 * The Organization Page
 * @returns {React.ReactElement}
 */
function OrganizationPage() {
    const { loading, list } = Store.useState("organization");

    const {
        fetchList, acceptOrganization, accessOrganization,
    } = Store.useAction("organization");


    // Load the Data
    React.useEffect(() => {
        fetchList();
    }, []);


    // Access to the Organization
    const handleAccess = async (elemID) => {
        await accessOrganization(elemID);
        Navigate.reload();
    };

    // Accepts a Organization Invite
    const handleAccept = async (elemID) => {
        await acceptOrganization(elemID);
        fetchList();
    };


    // Do the Render
    const hasOrganizations = list.length > 0;

    return <Main>
        <Header
            icon="client"
            message="ORGANIZATIONS_NAME"
        />

        <Content isLoading={loading}>
            {!hasOrganizations && <NoneAvailable
                message="ORGANIZATIONS_NONE_AVAILABLE"
            />}

            {hasOrganizations && <Container>
                {list.map((elem) => <Item key={elem.userID} isSelected={elem.isCurrent}>
                    <Title>{elem.organization}</Title>
                    <Subtitle>{elem.membersText}</Subtitle>

                    {elem.isCurrent && <div>
                        <Current>{NLS.get("ORGANIZATIONS_CURRENT")}</Current>
                    </div>}

                    {elem.isActive && <div>
                        <Active>{NLS.get("ORGANIZATIONS_ACTIVE")}</Active>
                        <Button
                            variant="outlined"
                            message="ORGANIZATIONS_ACCESS"
                            onClick={() => handleAccess(elem.userID)}
                        />
                    </div>}

                    {elem.isPending && <div>
                        <Pending>{NLS.get("ORGANIZATIONS_PENDING")}</Pending>
                        <Button
                            variant="outlined"
                            message="GENERAL_ACCEPT"
                            onClick={() => handleAccept(elem.userID)}
                        />
                    </div>}
                </Item>)}
            </Container>}
        </Content>
    </Main>;
}

export default OrganizationPage;
