import React                 from "react";
import PropTypes             from "prop-types";
import Store                 from "Dashboard/Core/Store";
import Navigate              from "Dashboard/Core/Navigate";
import Conversations         from "Utils/Conversations";
import Hooks                 from "Utils/Hooks";

// Components
import ConversationCreate    from "./ConversationCreate";
import ConversationSummarize from "./ConversationSummarize";
import ConversationFollowup  from "./ConversationFollowup";
import ContactBlock          from "Components/App/Client/Contact/Dialogs/ContactBlock";
import AccountAssign         from "Components/App/Client/Account/Dialogs/AccountAssign";
import TagEdit               from "Components/App/Config/Tag/TagEdit";

// Dashboard
import DeleteDialog          from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Conversation Dialogs
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationDialogs(props) {
    const { openDialog, setOpenDialog } = props;

    const { conversationTab, conversationHash } = Navigate.useParams();
    const conversationsNavigate = Conversations.useGoto();
    const clientID              = Hooks.useClientID();

    const { elem, contact } = Store.useState("conversation");
    const {
        deleteConversation, triggerElemUpdate, assignTag,
    } = Store.useAction("conversation");


    // The Current State
    const [ loading, setLoading ] = React.useState(false);


    // Handles the Conversation Create
    const handleCreate = (response) => {
        setOpenDialog("");
        if (response.hash !== conversationHash) {
            conversationsNavigate(conversationTab, response.hash);
        }
    };

    // Handles the Tag Create Action
    const handleTagCreate = (response) => {
        if (response.tagID) {
            assignTag(elem.id, response.tagID);
        }
        setOpenDialog("");
    };

    // Handles the Conversation Delete
    const handleDelete = async () => {
        setLoading(true);
        const result = await deleteConversation(elem.id);
        if (result.success) {
            conversationsNavigate(conversationTab);
        }
        setOpenDialog("");
        setLoading(false);
    };

    // Handles an Action
    const handleAction = () => {
        triggerElemUpdate();
        setOpenDialog("");
    };


    // Do the Render
    return <>
        <ConversationCreate
            open={openDialog === "create"}
            clientID={clientID}
            contactID={contact.id && !contact.isBlocked ? contact.id : 0}
            onSubmit={handleCreate}
            onClose={() => setOpenDialog("")}
        />
        <ConversationSummarize
            open={openDialog === "summarize"}
            clientID={clientID}
            conversationID={elem.conversationID}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <ConversationFollowup
            open={openDialog === "followup"}
            clientID={clientID}
            conversationID={elem.conversationID}
            onClose={() => setOpenDialog("")}
        />

        <TagEdit
            open={openDialog === "tag"}
            clientID={clientID}
            onSubmit={handleTagCreate}
            onClose={() => setOpenDialog("")}
        />
        <ContactBlock
            open={openDialog === "block"}
            elem={contact}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <AccountAssign
            openAssign={openDialog === "account"}
            elemName={contact.fullName}
            contactID={contact.id}
            clientID={contact.clientID}
            onSubmit={handleAction}
            onClose={() => setOpenDialog("")}
        />
        <DeleteDialog
            open={openDialog === "delete"}
            isLoading={loading}
            title="CONVERSATIONS_DELETE_TITLE"
            message="CONVERSATIONS_DELETE_TEXT"
            content={contact.fullName}
            onSubmit={handleDelete}
            onClose={() => setOpenDialog("")}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationDialogs.propTypes = {
    openDialog    : PropTypes.string.isRequired,
    setOpenDialog : PropTypes.func.isRequired,
};

export default ConversationDialogs;
