import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import Zoom                 from "Components/Utils/Common/Zoom";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const Container = Styled.header`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-right: 4px;
    padding: 10px 4px;
    gap: 8px;
    border-bottom: 1px solid var(--border-color-light);
    overflow: auto;
    z-index: 1;

    @media screen and (max-width: 450px) {
        .btn {
            padding: 4px 8px;
        }
        .btn-content {
            display: none;
        }
    }
`;

const Actions = Styled.section`
    display: flex;
    gap: 8px;
`;

const Input = Styled(InputField)`
    width: 160px;

    .input-content {
        min-height: 26px;
        padding: 2px 10px;
    }
    a {
        margin-bottom: -4px;
    }

    @media (max-width: 700px) {
        display: none;
    }
`;



/**
 * The Flow Actions
 * @returns {React.ReactElement}
 */
function FlowActions() {
    const { flowID, nodes } = Store.useState("flowEditor");
    const { hasChanges, zoom, selectedNode, selectedNodes, selectedEdge, search } = Store.useState("flowState");

    const { copyNodes, deleteEdge } = Store.useAction("flowEditor");
    const { setAction, setZoom, setEdgeEditing, setSearch, setDetailsTab } = Store.useAction("flowState");


    // Toggles the Nodes Box
    const toggleNodes = () => {
        if (hasChanges) {
            setAction("WARNING");
        } else {
            setAction("CREATE");
        }
    };

    // Handles the Copy
    const handleCopy = () => {
        if (hasChanges) {
            setAction("WARNING");
        } else {
            copyNodes(flowID, JSON.stringify(selectedNodes));
        }
    };

    // Handles the Delete
    const handleDelete = () => {
        if (selectedNodes.length) {
            setAction("REMOVE");
        }
        if (selectedEdge) {
            deleteEdge(selectedEdge.id);
        }
    };

    // Handles the Zoom Minus
    const handleZoomMinus = (e) => {
        setZoom(flowID, zoom - 10);
        e.preventDefault();
    };

    // Handles the Zoom Plus
    const handleZoomPlus = (e) => {
        setZoom(flowID, zoom + 10);
        e.preventDefault();
    };

    // Handles the Zoom Reset
    const handleZoomReset = (e) => {
        setZoom(flowID, 100);
        e.preventDefault();
    };

    // Handles the Search
    const handleSearch = (name, value) => {
        setSearch(value);
        setDetailsTab("editor");
    };


    // Variables
    const isInitial = selectedNode ? Utils.getValue(nodes, "id", selectedNode, "isInitial") : false;
    const canEdit   = selectedNodes.length > 1 || (selectedNodes.length === 1 && !isInitial);
    const canDelete = Boolean(canEdit || selectedEdge);


    // Do the Render
    return <Container className="no-scrollbars">
        <Actions>
            <Button
                variant="primary"
                message="FLOWS_NODES_SINGULAR"
                icon="add"
                onClick={toggleNodes}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="outlined"
                message="GENERAL_COPY"
                icon="copy"
                onClick={handleCopy}
                isSmall
            />
            <Button
                isHidden={!canEdit}
                variant="outlined"
                message="GENERAL_MOVE"
                icon="move"
                onClick={() => setAction("MOVE")}
                isSmall
            />
            <Button
                isHidden={!selectedEdge}
                variant="outlined"
                message="GENERAL_EDIT"
                icon="edit"
                onClick={() => setEdgeEditing(true)}
                isSmall
            />
            <Button
                isHidden={!canDelete}
                variant="outlined"
                message="GENERAL_DELETE"
                icon="delete"
                onClick={handleDelete}
                isSmall
            />
        </Actions>

        <Actions>
            <Zoom
                zoom={zoom}
                onMinus={handleZoomMinus}
                onPlus={handleZoomPlus}
                onReset={handleZoomReset}
            />
            <Input
                name="search"
                icon="search"
                placeholder="GENERAL_SEARCH"
                autoComplete="new-password"
                value={search}
                onChange={handleSearch}
                onClear={() => setSearch("")}
                hasClear={search.length > 2}
                isSmall
            />
        </Actions>
    </Container>;
}

export default FlowActions;
