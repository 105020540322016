// The App Actions
const actions = [
    // Ticket Actions
    {
        name    : "ASSIGN",
        icon    : "assign",
        message : "TICKETS_ASSIGN",
    },
    {
        name    : "UNASSIGN",
        icon    : "delete",
    },
    {
        name    : "OPEN",
        icon    : "status",
        message : "GENERAL_OPEN",
    },
    {
        name    : "WAIT",
        icon    : "waitlist",
        message : "TICKETS_WAIT",
    },
    {
        name    : "CLOSE",
        icon    : "status",
        message : "TICKETS_CLOSE",
    },
    {
        name    : "MUTE",
        icon    : "mute",
        message : "TICKETS_MUTE",
    },
    {
        name    : "UNMUTE",
        icon    : "unmute",
        message : "TICKETS_UNMUTE",
    },
    {
        name    : "IMAGE",
        icon    : "image",
    },


    // Conversation Actions
    {
        name    : "CONVERSATION",
        icon    : "conversation",
    },
    {
        name    : "RETURN",
        icon    : "return",
    },
    {
        name    : "RESOLVE",
        icon    : "check",
    },


    // Contact Actions
    {
        name    : "COMBINE",
        icon    : "merge",
        message : "GENERAL_MERGE",
    },
    {
        name    : "MERGE",
        icon    : "merge",
        message : "GENERAL_MERGE",
    },
    {
        name    : "BLOCK",
        icon    : "block",
    },
    {
        name    : "MULTIDEL",
        icon    : "delete",
        message : "GENERAL_DELETE",
    },


    // Campaign/Integration Actions
    {
        name    : "ACTIVATE",
        icon    : "check",
        message : "GENERAL_ACTIVATE",
    },
    {
        name    : "PAUSE",
        icon    : "pause",
        message : "GENERAL_PAUSE",
    },
    {
        name    : "COMPLETE",
        icon    : "check",
        message : "GENERAL_COMPLETE",
    },


    // Module Actions
    {
        name    : "CLIENT",
        icon    : "client",
    },
    {
        name    : "PARTNER",
        icon    : "partner",
    },
    {
        name    : "CAMPAIGN",
        icon    : "campaign",
    },
    {
        name    : "CONTACT",
        icon    : "contact",
    },
    {
        name    : "HOSPITALITY",
        icon    : "hospitality",
    },
    {
        name    : "ORDER",
        icon    : "order",
    },
    {
        name    : "ACCOUNT",
        icon    : "account",
    },
    {
        name    : "TEAM",
        icon    : "team",
    },
    {
        name    : "ASSISTANT",
        icon    : "assistant",
    },
    {
        name    : "HOTEL",
        icon    : "hotel",
    },
    {
        name    : "FLOW",
        icon    : "flow",
    },
    {
        name    : "INTEGRATION",
        icon    : "integration",
    },


    // Special Actions
    {
        name    : "PRINT",
        icon    : "print",
        message : "GENERAL_PRINT",
    },
    {
        name    : "MOVE",
        icon    : "move",
        message : "GENERAL_MOVE",
    },
    {
        name    : "RESET",
        icon    : "password",
    },
    {
        name    : "PERSONALIZE",
        icon    : "personalize",
        message : "PERSONALIZE_NAME",
    },
    {
        name    : "INVITE",
        icon    : "invite",
    },
    {
        name    : "RESEND",
        icon    : "send",
    },
    {
        name    : "APPROVE",
        icon    : "check",
    },
    {
        name    : "PROFILE",
        icon    : "profile",
    },
    {
        name    : "SIMULATE",
        icon    : "simulate",
    },
    {
        name    : "PUBLISH",
        icon    : "publish",
        message : "GENERAL_PUBLISH",
    },
    {
        name    : "CLEAR",
        icon    : "clear",
        message : "GENERAL_CLEAR_CHANGES",
    },
    {
        name    : "CODE",
        icon    : "code",
        message : "GENERAL_GET_CODE",
    },
    {
        name    : "WARNING",
        icon    : "status",
    },
    {
        name    : "ERROR",
        icon    : "error",
    },
];

export default actions;
