import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import KeyCode              from "Dashboard/Utils/KeyCode";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";
import IconLink             from "Dashboard/Components/Link/IconLink";
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.div`
    padding: 8px 12px;
    border-top: 1px solid var(--border-color-light);
`;

const Content = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const Textarea = Styled(InputField)`
    flex-grow: 2;
`;

const PreContent = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 8px;
    color: black;
    background-color: rgb(235, 236, 240);
    border-radius: 8px;
`;
const PreContentText = Styled.div`
    width: calc(100% - 16px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
const PreContentClose = Styled(Icon)`
    font-size: 14px;
    cursor: pointer;
`;

const FileError = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(188, 28, 72);
    border-radius: 6px;
    color: white;
    padding: 4px 8px;
    margin-bottom: 4px;
`;

const FileInput = Styled.input`
    display: none;
`;



/**
 * The Flow Sim Send
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimSend(props) {
    const { onSubmit } = props;

    const { messageLength } = Store.useState("flowSimulation");


    // The References
    const emojiRef = React.useRef(null);
    const fileRef  = React.useRef(null);
    const inputRef = React.useRef(null);

    // The Current State
    const [ message,         setMessage         ] = React.useState("");
    const [ file,            setFile            ] = React.useState(null);
    const [ fileName,        setFileName        ] = React.useState("");
    const [ sizeError,       setSizeError       ] = React.useState(false);
    const [ emojiOpen,       setEmojiOpen       ] = React.useState(false);
    const [ sendingLocation, setSendingLocation ] = React.useState(false);


    // Handles the Message
    const handleMessage = (name, message) => {
        setMessage(message);
    };

    // Handles the Message KeyDown
    const handleKeyDown = (e) => {
        if (e.keyCode === KeyCode.DOM_VK_RETURN && !e.shiftKey) {
            handleSubmit();
            e.preventDefault();
        }
    };

    // Handles the Emoji Open
    const handleEmojiOpen = () => {
        setEmojiOpen(!emojiOpen);
    };

    // Handles the Emoji Insert
    const handleEmojiInsert = (emoji) => {
        setMessage(message + emoji);
        setEmojiOpen(false);
    };

    // Handles the File Open
    const handleFileOpen = () => {
        fileRef.current.click();
    };

    // Handles the File Change
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const size = file.size / (1024 * 1024);
            if (size > 3) {
                setSizeError(true);
            } else {
                setFile(file);
                setFileName(file.name);
                setSizeError(false);
            }
        }
    };

    // Handles the File Remove
    const handleFileRemove = () => {
        setFile(null);
        setFileName("");
        setSizeError(false);
        fileRef.current.value = "";
    };

    // Handles the Location
    const handleLocation = () => {
        setSendingLocation(true);
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            onSubmit({ latitude, longitude });
            setSendingLocation(false);
            inputRef.current.focus();
        });
    };

    // handles the Submit
    const handleSubmit = () => {
        if (!message && !file) {
            return;
        }
        setMessage("");
        handleFileRemove();
        onSubmit({ message, file });
        inputRef.current.focus();
    };


    // Do the Render
    const hasLocation = Boolean(navigator.geolocation);

    return <>
        <Container>
            {sizeError && <FileError>
                <PreContentText>{NLS.get("MEDIA_ERROR_UPLOAD_SIZE")}</PreContentText>
                <PreContentClose
                    icon="close"
                    onClick={() => setSizeError(false)}
                />
            </FileError>}

            {!!fileName && <PreContent>
                <PreContentText>{fileName}</PreContentText>
                <PreContentClose
                    icon="close"
                    onClick={handleFileRemove}
                />
            </PreContent>}

            {sendingLocation && <PreContent>
                <PreContentText>{NLS.get("CONVERSATIONS_SENDING_LOCATION")}</PreContentText>
            </PreContent>}

            <Content>
                <Textarea
                    passedRef={inputRef}
                    type="textarea"
                    name="message"
                    label="CONVERSATIONS_WRITE_TEXT"
                    value={message}
                    onChange={handleMessage}
                    onKeyDown={handleKeyDown}
                    onSubmit={handleSubmit}
                    maxLength={messageLength}
                    rows="1"
                    maxRows="8"
                >
                    <IconLink
                        passedRef={emojiRef}
                        variant="black"
                        icon="emoji"
                        onClick={handleEmojiOpen}
                        isSmall
                    />
                    <IconLink
                        variant="black"
                        icon="attachment"
                        onClick={handleFileOpen}
                        isSmall
                    />
                    {hasLocation && <IconLink
                        variant="black"
                        icon="address"
                        onClick={handleLocation}
                        isSmall
                    />}
                </Textarea>
                <IconLink
                    variant="primary"
                    icon="send"
                    onClick={handleSubmit}
                    isDisabled={sendingLocation}
                />
            </Content>

            <FileInput
                ref={fileRef}
                type="file"
                onChange={handleFileChange}
            />
        </Container>

        <EmojiPopup
            open={emojiOpen}
            targetRef={emojiRef}
            direction="top left"
            gap={4}
            onClick={handleEmojiInsert}
            onClose={() => setEmojiOpen(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimSend.propTypes = {
    onSubmit : PropTypes.func.isRequired,
};

export default FlowSimSend;
