import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Commons              from "Utils/Commons";
import { User }             from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canFilter     : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    elem          : { id : 0 },
    userTeams     : [],
    conversations : [],
    editElem      : {},
    teams         : [],
    colors        : [],
    accesses      : [],
    statuses      : [],
    hasEmail      : false,
    filters       : {},
    hasFilters    : false,
    sort          : {
        orderBy  : "firstName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "USER_LOADING" });
    },

    /**
     * Fetches the User List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}, sort = {}) {
        const params = { ...filters, ...sort };
        Navigate.unsetParams(params);
        if (type === "PARTNER") {
            params.partnerID = elemID;
        } else if (type === "CLIENT") {
            params.clientID = elemID;
        }
        const data   = await User.getAll(params);
        data.filters = filters;
        data.sort    = sort;
        dispatch({ type : "USER_LIST", data });
    },

    /**
     * Fetches a single User
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    async fetchElem(dispatch, userID) {
        const data = await User.getOne({ userID });
        dispatch({ type : "USER_ELEM", data });
    },

    /**
     * Fetches the User Edit data
     * @param {Function} dispatch
     * @param {Number}   userID
     * @param {Number}   clientID
     * @param {Number}   partnerID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, userID, clientID, partnerID) {
        const data = await User.getEditData({ userID, clientID, partnerID });
        dispatch({ type : "USER_EDIT", data });
    },

    /**
     * Removes the single User
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async removeElem(dispatch) {
        dispatch({ type : "USER_REMOVE" });
    },

    /**
     * Creates an User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createUser(dispatch, data) {
        return User.create(data);
    },

    /**
     * Invites an User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    inviteUser(dispatch, data) {
        return User.invite(data);
    },

    /**
     * Resends the User Invite
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    resendInvite(dispatch, userID) {
        return User.resendInvite({ userID });
    },

    /**
     * Edits the given User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editUser(dispatch, data) {
        return User.edit(data);
    },

    /**
     * Deletes a User
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    deleteUser(dispatch, userID) {
        return User.delete({ userID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.email           = elem.email       || elem.inviteEmail;
    elem.displayName     = elem.displayName || elem.email;

    Commons.parseQualifications(elem);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "USER_LIST", "USER_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "USER_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "USER_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canFilter     : action.data.canFilter,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : Utils.parseList(action.data.list, parseElem),
            total         : action.data.total,
            filters       : action.data.filters,
            hasFilters    : !Utils.isEmpty(action.data.filters),
            sort          : action.data.sort,
        };

    case "USER_ELEM":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            canEdit       : action.data.canEdit,
            elem          : parseElem(action.data.elem),
            userTeams     : action.data.userTeams,
            conversations : Conversations.parseList(action.data.conversations),
        };

    case "USER_EDIT":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            editElem      : parseElem(action.data.elem),
            teams         : action.data.teams,
            colors        : action.data.colors,
            accesses      : action.data.accesses,
            statuses      : action.data.statuses,
            hasEmail      : action.data.hasEmail,
        };

    case "USER_REMOVE":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            elem          : { ...initialState.elem },
            userTeams     : [],
            conversations : [],
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
