import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Store Header
 * @returns {React.ReactElement}
 */
function StoreHeader() {
    const { storeTab } = Navigate.useParams();

    const { hasAssistantAI } = Store.useState("permission");
    const { canEdit, elem } = Store.useState("store");

    const { withAssistant } = Store.useState("storeState");
    const { setAction, setAssistant } = Store.useAction("storeState");

    const { openDetails } = Store.useAction("core");


    // Handles the Action
    const handleAction = (action) => {
        if (action.isView) {
            const url = `${process.env.REACT_APP_STORE}preview/${elem.hash}`;
            Navigate.gotoUrl(url, true);
        } else if (action.isAssistant) {
            setAssistant(!withAssistant);
            if (!withAssistant) {
                openDetails();
            }
        } else {
            setAction(action);
        }
    };


    // Variables
    const isActive      = elem.isActive || elem.isPending;
    const isPersonalize = Navigate.isUrl("PERSONALIZE", storeTab);


    // Do the Render
    return <Header
        message={elem.name || "STORES_SINGULAR"}
        icon="store"
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                isHidden={!hasAssistantAI || !elem.assistantID}
                variant={withAssistant ? "primary" : "outlined"}
                action="ASSISTANT"
                message="ASSISTANTS_THREADS_NAME"
            />
            <ActionItem
                isHidden={isActive}
                variant="primary"
                action="ACTIVATE"
            />
            <ActionItem
                isHidden={!isActive || !isPersonalize}
                variant={elem.needsPublish ? "primary" : "outlined"}
                action="PUBLISH"
                withMark={elem.needsPublish}
            />

            <ActionItem
                isHidden={!canEdit}
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="VIEW"
                    message="STORES_VIEW_TITLE"
                />
                <ActionOption
                    action="EDIT"
                    message="STORES_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!isActive}
                    action="PAUSE"
                    message="STORES_PAUSE_TITLE"
                />
                <ActionOption
                    isHidden={!isActive || !elem.needsPublish}
                    action="PUBLISH"
                    message="GENERAL_PUBLISH_CHANGES"
                />
                <ActionOption
                    isHidden={!isActive || !elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="STORES_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

export default StoreHeader;
