import React                from "react";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";
import EditorField          from "Dashboard/Components/Form/EditorField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Text = Styled.p`
    margin: 0 0 calc(var(--main-gap) * 2) 0;
`;

const Footer = Styled.footer`
    display: flex;
    justify-content: flex-end;
    margin-top: var(--main-gap);
    padding: 8px 8px 8px 16px;
    border-top: 1px solid var(--border-color-light);
`;



/**
 * The Email Signatures Page
 * @returns {React.ReactElement}
 */
function EmailSignaturePage() {
    const { loading, list } = Store.useState("emailSignature");
    const { fetchList, editEmailSignature } = Store.useAction("emailSignature");


    // The Current State
    const [ userID,         setUserID         ] = React.useState(0);
    const [ clientID,       setClientID       ] = React.useState(0);
    const [ emailSignature, setEmailSignature ] = React.useState("");

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();


    // Load the Data
    React.useEffect(() => {
        handleLoad();
    }, []);

    // Handles the Load
    const handleLoad = async () => {
        const response = await fetchList();
        if (response.list.length) {
            setUserID(response.list[0].userID);
            setClientID(response.list[0].clientID);
            setEmailSignature(response.list[0].emailSignature);
        } else {
            setUserID(0);
            setClientID(0);
            setEmailSignature("");
        }
    };

    // Handles the Tab
    const handleTab = (userID) => {
        const index = list.findIndex((elem) => elem.userID === userID);
        setUserID(userID);
        setClientID(list[index].clientID);
        setEmailSignature(list[index].emailSignature);
    };

    // Handles the Submit
    const handleSubmit = () => {
        editEmailSignature(userID, emailSignature);
    };


    // Do the Render
    const hasOrganizations = list.length > 0;

    return <Main>
        <Header
            icon="email"
            message="EMAIL_SIGNATURES_NAME"
        />

        <Content isLoading={loading}>
            {!hasOrganizations && <NoneAvailable
                message="EMAIL_SIGNATURES_NONE_AVAILABLE"
            />}

            {hasOrganizations && <>
                <Text>{NLS.get("EMAIL_SIGNATURES_TEXT")}</Text>
                <TabList
                    variant="lined"
                    selected={userID}
                    onClick={handleTab}
                >
                    {list.map((elem) => <TabItem
                        key={elem.userID}
                        value={elem.userID}
                        message={elem.organization}
                    />)}
                </TabList>

                <EditorField
                    name="emailSignature"
                    value={emailSignature}
                    clientID={clientID}
                    maxHeight={400}
                    onChange={(name, value) => setEmailSignature(value)}
                    onMedia={handleMediaOpen}
                />
                <MediaDialog
                    open={showMedia}
                    clientID={clientID}
                    mediaType={mediaType}
                    onSubmit={handleMediaSubmit}
                    onClose={handleMediaClose}
                />

                <Footer>
                    <Button
                        isLoading={loading}
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={handleSubmit}
                    />
                </Footer>
            </>}
        </Content>
    </Main>;
}

export default EmailSignaturePage;
