import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Components
import ListHeader           from "./ListHeader";
import ListSearch           from "./ListSearch";
import ListContent          from "./ListContent";
import ListProviders        from "./ListProviders";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: var(--main-height, var(--full-height));
    border-radius: var(--main-radius);
    background-color: white;

    header {
        flex-shrink: 0;
    }
`;

const Inside = Styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: 0 var(--main-padding) var(--main-padding);
    overflow: auto;
`;



/**
 * The List Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ListContainer(props) {
    const { fetch } = props;

    const { loading } = Store.useState("conversation");


    // Do the Render
    return <Container>
        <Header
            message="CONVERSATIONS_NAME"
            icon="conversation"
        />

        {!loading && <Inside>
            <ListHeader />
            <ListSearch />
            <ListContent fetch={fetch} />
            <ListProviders />
        </Inside>}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ListContainer.propTypes = {
    fetch : PropTypes.func.isRequired,
};

export default ListContainer;
