import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";



// Styles
const Day = Styled.header`
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0;
    background-color: var(--bubble-back-color);
    z-index: 2;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 32px;
        display: block;
        width: calc(100% - 64px);
        height: 1px;
        background-color: var(--border-color-light);
    }
`;

const Title = Styled.h3`
    position: relative;
    margin: 0;
    padding: 4px 16px;
    text-align: center;
    font-size: 10px;
    color: white;
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    font-weight: normal;
    z-index: 1;
`;



/**
 * The Message Day
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MessageDay(props) {
    const { className, dayName } = props;


    // Do the Render
    if (!dayName) {
        return <React.Fragment />;
    }
    return <Day>
        <Title className={className}>{dayName}</Title>
    </Day>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MessageDay.propTypes = {
    className : PropTypes.string,
    dayName   : PropTypes.string,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
MessageDay.defaultProps = {
    className : "",
};

export default MessageDay;
