import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import Campaign             from "Utils/Campaign";

// Dashboard
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div.attrs(({ hasDelete }) => ({ hasDelete }))`
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;

    ${(elem) => !elem.hasDelete && `
        margin-right: calc(32px + 12px);
    `}
`;

const Content = Styled(Columns)`
    width: 100%;
`;

const Buttons = Styled.div`
    display: flex;
    gap: 12px;
`;



/**
 * The Campaign Condition
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignCondition(props) {
    const { groupID, item, setCreating, getGlobalError } = props;

    const { elem, selects } = Store.useState("campaign");
    const { createCondition, editCondition, deleteCondition } = Store.useAction("campaign");


    // The References
    const timerRef = React.useRef(null);

    // The Initial Data
    const initialData = {
        campaignID    : elem.campaignID,
        groupID       : groupID,
        conditionID   : 0,
        conditionName : "",
        fieldID       : "",
        tongueID      : "",
        tagID         : "",
        teamID        : "",
        hotelID       : "",
        operator      : "",
        value         : "",
        date          : "",
    };

    // The Initial Data
    const initialErrors = {
        conditionName : "",
        fieldID       : "",
        tongueID      : "",
        tagID         : "",
        teamID        : "",
        hotelID       : "",
        operator      : "",
        value         : "",
    };

    // The Current State
    const [ data,   setData   ] = React.useState({ ...initialData, ...item });
    const [ errors, setErrors ] = React.useState({ ...initialErrors });

    // Some Variables
    const isCreate = !item.id;


    // Returns the Current Error
    const getError = (key) => {
        return getGlobalError(errors, key, "conditions", item.conditionID);
    };

    // Handles the Input Change
    const handleChange = (name, value) => {
        let newData = { ...data, [name] : value };
        if (name === "conditionName") {
            newData = { ...initialData, conditionName : value };
        }
        setData(newData);
        setErrors({ ...errors, [name] : "" });

        if (isCreate) {
            return;
        }
        Utils.setTimeout(timerRef, () => handleEdit(newData), 1000);
    };

    // Handles the Create
    const handleCreate = async () => {
        try {
            setErrors({ ...initialData });
            await createCondition(data);
            setCreating(false);
        } catch (errors) {
            setErrors(errors);
        }
    };

    // Handles the Edit
    const handleEdit = async (newData) => {
        try {
            setErrors({ ...initialErrors });
            await editCondition(newData);
            timerRef.current = null;
        } catch (errors) {
            setErrors(errors);
        }
    };


    // Get the Fields
    const conditionName = String(data.conditionName).charAt(0).toLowerCase() + String(data.conditionName).slice(1);
    const fields        = selects[`${conditionName}s`] ?? [];

    // Handle the Operator Field
    const operatorOptions = React.useMemo(() => {
        if (Campaign.requiresBeforeOrAfter(data.conditionName)) {
            return "SELECT_OPERATORS_BEFORE_OR_AFTER";
        }

        const field = Utils.getValue(fields, "key", data.fieldID);
        if (field.type === "list") {
            return "SELECT_OPERATORS_LIST";
        }
        if (field.type === "date") {
            return "SELECT_OPERATORS_DATE";
        }
        if (field.type === "number") {
            return "SELECT_OPERATORS_NUMBER";
        }
        if (field.type === "toggle") {
            return "SELECT_OPERATORS_TOGGLE";
        }
        return "SELECT_OPERATORS";
    }, [ JSON.stringify(data) ]);

    // Handle the Value Field
    const valueField = React.useMemo(() => {
        return Commons.getCustomFieldInput(data, fields);
    }, [ JSON.stringify(data), JSON.stringify(fields) ]);


    // Variables
    const hasContactField      = Campaign.useHasContactField(data.conditionName);
    const hasContactTongue     = Campaign.useHasContactTongue(data.conditionName);
    const hasTag               = Campaign.useHasTag(data.conditionName);
    const hasConversationField = Campaign.useHasConversationField(data.conditionName);
    const hasConversationTeam  = Campaign.useHasConversationTeam(data.conditionName);
    const hasHospitalityField  = Campaign.useHasHospitalityField(data.conditionName);
    const hasHotel             = Campaign.useHasHotel(data.conditionName);
    const hasOperator          = Campaign.useHasOperator(data.conditionName);
    const hasValue             = Campaign.useHasValue(data.conditionName, data.operator);
    const hasValueDate         = Campaign.useHasValueDate(data.conditionName);


    // Do the Render
    return <>
        <Container className="condition-square" hasDelete={!isCreate}>
            <Content amount="4">
                <InputField
                    type="select"
                    name="conditionName"
                    label="GENERAL_CONDITION"
                    options={selects.conditions}
                    value={data.conditionName}
                    error={getError("conditionName")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasContactField}
                    type="select"
                    name="fieldID"
                    label="GENERAL_FIELD"
                    options={selects.contactFields}
                    value={data.fieldID}
                    error={getError("fieldID")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasContactTongue}
                    type="select"
                    name="tongueID"
                    label="LANGUAGES_SINGULAR"
                    options={selects.tongues}
                    value={data.tongueID}
                    error={getError("tongueID")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasTag}
                    type="select"
                    name="tagID"
                    label="TAGS_SINGULAR"
                    options={selects.tags}
                    value={data.tagID}
                    error={getError("tagID")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasConversationField}
                    type="select"
                    name="fieldID"
                    label="GENERAL_FIELD"
                    options={selects.conversationFields}
                    value={data.fieldID}
                    error={getError("fieldID")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasConversationTeam}
                    type="select"
                    name="teamID"
                    label="TEAMS_SINGULAR"
                    options={selects.teams}
                    value={data.teamID}
                    error={getError("teamID")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasHospitalityField}
                    type="select"
                    name="fieldID"
                    label="GENERAL_FIELD"
                    options={selects.hospitalityFields}
                    value={data.fieldID}
                    error={getError("fieldID")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasHotel}
                    type="select"
                    name="hotelID"
                    label="HOTELS_SINGULAR"
                    options={selects.hotels}
                    value={data.hotelID}
                    error={getError("hotelID")}
                    onChange={handleChange}
                    noneText="GENERAL_ANY"
                    shrinkLabel
                />
                <InputField
                    isHidden={!hasOperator}
                    type="select"
                    name="operator"
                    label="GENERAl_OPERATOR"
                    options={operatorOptions}
                    value={data.operator}
                    error={getError("operator")}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
                <InputField
                    isHidden={!hasValue}
                    type={valueField.type}
                    name="value"
                    label="GENERAL_VALUE"
                    options={valueField.options}
                    value={data.value}
                    error={getError("value")}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    isHidden={!hasValueDate}
                    type="date"
                    name="date"
                    label="GENERAL_DATE"
                    value={data.date}
                    error={getError("date")}
                    onChange={handleChange}
                    isRequired
                />
            </Content>

            <IconLink
                isHidden={isCreate}
                variant="black"
                icon="delete"
                onClick={() => deleteCondition(data.conditionID)}
            />
        </Container>

        {isCreate && <Buttons className="condition-square">
            <Button
                variant="outlined"
                message="GENERAL_SAVE"
                onClick={handleCreate}
            />
            <Button
                variant="outlined"
                message="GENERAL_CANCEL"
                onClick={() => setCreating(false)}
            />
        </Buttons>}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignCondition.propTypes = {
    groupID        : PropTypes.number,
    item           : PropTypes.object,
    setCreating    : PropTypes.func,
    getGlobalError : PropTypes.func.isRequired,
    forGroup       : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CampaignCondition.defaultProps = {
    groupID  : 0,
    item     : {},
    forGroup : false,
};

export default CampaignCondition;
