import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";



// Styles
const Frame = Styled.iframe.attrs(({ open, height }) => ({ open, height }))`
    display: ${(props) => props.open ? "block" : "none"};
    position: fixed;
    top: calc(var(--topbar-height) - 20px);
    right: 10px;
    width: 400px;
    height: ${(props) => props.height}px;
    pointer-events: all;
    z-index: 1000000001;
    border: none;

    @media (max-width: 500px) {
        width: 100%;
        right: 0;
        top: 0;
        height: 100%;
    }
`;



/**
 * The Chat Support
 * @returns {React.ReactElement}
 */
function ChatSupport() {
    const { isAnyAdmin, credential } = Store.useState("auth");

    const { showChatSupport } = Store.useState("global");
    const { setShowChatSupport, setChatSupportUnread } = Store.useAction("global");


    // The Current State
    const [ height, setHeight ] = React.useState(window.innerHeight - 36 - 30 + 1);


    // Adds or removes the message event listener
    React.useEffect(() => {
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    // Handles the message
    const handleMessage = (event) => {
        if (typeof event.data === "object") {
            return;
        }

        const data = event.data || "";
        if (data === "close") {
            setShowChatSupport(false);
        } else if (data.startsWith("height")) {
            const innerHeight = parseInt(data.replace("height-", ""), 10) + 30;
            const height      = Math.min(window.innerHeight - 30, innerHeight);
            setHeight(height);
        } else if (data.startsWith("unread")) {
            const unreadCount = parseInt(data.replace("unread-", ""), 10);
            setChatSupportUnread(unreadCount);
            if (unreadCount > 0) {
                setShowChatSupport(true);
            }
        }
    };


    // Variables
    let url = `${process.env.REACT_APP_CHAT_URL}${process.env.REACT_APP_CHAT_HASH}`;
    if (!isAnyAdmin) {
        url += `?name=${credential.name || ""}&email=${credential.email || ""}`;
    }


    // Do the Render
    return <Frame
        src={url}
        open={showChatSupport}
        height={height}
    />;
}

export default ChatSupport;
