import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Responsive           from "Dashboard/Core/Responsive";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";

// Components
import ConversationActions  from "./ConversationActions";
import ConversationDialogs  from "./ConversationDialogs";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



// Styles
const Container = Styled(Header).attrs(({ singleButton }) => ({ singleButton }))`
    overflow: auto;

    & > h2 {
        display: none;
    }

    & > div {
        display: flex;
        justify-content: ${(props) => props.singleButton ? "flex-end" : "space-between"};
        flex-wrap: wrap;
        gap: 8px;
    }

    @media (max-width: 850px) {
        display: flex;
        & > div {
            justify-content: flex-end;
        }
        .btn.btn {
            padding: 4px 8px;
        }
        .btn-preicon {
            margin-right: 0;
        }
        .btn-content {
            display: none;
        }
    }

    @media (max-width: ${Responsive.WIDTH_FOR_MOBILE}px) {
        & > h2 {
            display: flex;
        }
    }
`;



/**
 * The Conversation Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationHeader(props) {
    const { isUnreadRef } = props;

    const { conversationHash }  = Navigate.useParams();
    const parent                = Navigate.useParent();
    const conversationsNavigate = Conversations.useGoto();

    const { isClientUser } = Store.useState("auth");

    const data = Store.useState("conversation");
    const { canEdit, elem, contact, providers } = data;

    const {
        acceptConversation, returnConversation, progressConversation,
        resolveConversation, openConversation,
    } = Store.useAction("conversation");


    // The Current State
    const [ openDialog, setOpenDialog ] = React.useState("");


    // Handles the Accept Action
    const handleAccept = () => {
        acceptConversation(elem.id);
        conversationsNavigate("PROGRESS", elem.hash);
    };

    // Handles the Return Action
    const handleReturn = () => {
        returnConversation(elem.id);
    };

    // Handles the Progress Action
    const handleProgress = () => {
        if (elem.isFollowup) {
            progressConversation(elem.id);
        }
    };

    // Handles the Resolve Action
    const handleResolve = () => {
        if (elem.isProgress || elem.isFollowup) {
            resolveConversation(elem.id);
        }
    };

    // Handles the Open Action
    const handleOpen = () => {
        if (elem.isResolved) {
            openConversation(elem.id);
        }
    };


    // Variables
    const isMobile     = Utils.isMobile();
    const goBack       = isMobile && conversationHash;

    const showCreate   = Boolean(canEdit && providers.length && !conversationHash);
    const canAccept    = Boolean(isClientUser && elem.isQueue);
    const canReturn    = Boolean(elem.isProgress || elem.isFollowup);
    const canProgress  = Boolean(elem.isFollowup);
    const canFollowup  = Boolean(elem.isProgress);
    const canResolve   = Boolean(elem.isProgress || elem.isFollowup);
    const canOpen      = Boolean(elem.isResolved && !elem.channelIsDeleted && contact.id && !contact.isBlocked);
    const singleButton = Boolean(!canAccept && !canReturn && !canProgress && !canFollowup && !canResolve && !canOpen);


    // Do the Render
    return <>
        <Container
            className="no-scrollbars"
            message="CONVERSATIONS_SINGULAR"
            icon="conversation"
            href={goBack ? parent : null}
            singleButton={singleButton}
        >
            <ActionList>
                <ActionItem
                    isHidden={!canAccept}
                    icon="progress"
                    message="GENERAL_ACCEPT"
                    onClick={handleAccept}
                />
                <ActionItem
                    isHidden={!canReturn}
                    icon="queue"
                    message="CONVERSATIONS_RETURN"
                    onClick={handleReturn}
                />
                <ActionItem
                    isHidden={!canFollowup}
                    icon="followup"
                    message="CONVERSATIONS_FOLLOWUP"
                    onClick={() => setOpenDialog("followup")}
                />
                <ActionItem
                    isHidden={!canProgress}
                    icon="progress"
                    message="CONVERSATIONS_PROGRESS"
                    onClick={handleProgress}
                />
                <ActionItem
                    isHidden={!canResolve}
                    icon="resolved"
                    message="CONVERSATIONS_RESOLVE"
                    onClick={handleResolve}
                />
                <ActionItem
                    isHidden={!canOpen}
                    icon="progress"
                    message="GENERAL_OPEN"
                    onClick={handleOpen}
                />
            </ActionList>

            <ActionList>
                <ActionItem
                    isHidden={!showCreate}
                    action="CREATE"
                    onClick={() => setOpenDialog("create")}
                />
                <ConversationActions
                    isUnreadRef={isUnreadRef}
                    setOpenDialog={setOpenDialog}
                />
            </ActionList>
        </Container>

        <ConversationDialogs
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationHeader.propTypes = {
    isUnreadRef : PropTypes.object.isRequired,
};

export default ConversationHeader;
