import React                from "react";
import PropTypes            from "prop-types";

// Components
import ProviderLogContent   from "./ProviderLogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";



/**
 * The Provider Log List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProviderLogList(props) {
    const { type } = props;


    // Do the Render
    return <Main>
        <Header message="PROVIDERS_LOG_NAME" icon="provider-log" />
        <Content>
            <ProviderLogContent
                type={type}
                withNavigate
            />
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProviderLogList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ProviderLogList;
