import Store                from "Dashboard/Core/Store";
import CoreAuth             from "Dashboard/Core/Auth";
import CoreAjax             from "Dashboard/Core/Ajax";
import Utils                from "Dashboard/Utils/Utils";

// APIs
import {
    Login, Reset, Join,
} from "Utils/API";



// The initial State
const initialState = {
    isAuthenticated      : true,
    isLoggedIn           : false,
    isAnyAdmin           : false,
    isAnyUser            : false,
    isClientUser         : false,
    isSuperAdmin         : false,
    isAdmin              : false,
    isSupport            : false,
    isPartner            : false,
    isOwner              : false,
    isSupervisor         : false,
    isAgent              : false,
    isOnlyCredential     : false,
    loggedAsUser         : false,
    credential           : {},
    credentialID         : 0,
    currentClientID      : 0,
    currentPartnerID     : 0,
    currentUserID        : 0,
    email                : "",
    organizations        : {},
    hasOrganizations     : false,
    hasUserOrganizations : false,
    currOrganization     : "",
    organization         : {
        name          : "",
        hasCredential : false,
    },
    edition          : new Date().getTime(),
};



// The Actions
const actions = {
    /**
     * Sets the Current Credential
     * @param {Function} dispatch
     * @param {Object}   credential
     * @returns {Void}
     */
    setCurrentUser(dispatch, credential) {
        dispatch({ type : "AUTH_CURRENT_USER", credential });
    },

    /**
     * Logs In the User
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    async login(dispatch, data) {
        const result = await Login.login(data);
        if (result && result.reqPassChange) {
            dispatch({ type : "AUTH_CHANGE_EMAIL", email : result.email });
        }
        return result;
    },

    /**
     * Logs In the User with Google
     * @param {Function} dispatch
     * @param {String=}  accessToken
     * @returns {Promise}
     */
    async googleLogin(dispatch, accessToken) {
        const result = await Login.googleLogin({ accessToken });
        if (result && result.reqPassChange) {
            dispatch({ type : "AUTH_CHANGE_EMAIL", email : result.email });
        }
        return result;
    },

    /**
     * Logs In the User with Microsoft
     * @param {Function} dispatch
     * @param {String=}  idToken
     * @returns {Promise}
     */
    async microsoftLogin(dispatch, idToken) {
        const result = await Login.microsoftLogin({ idToken });
        if (result && result.reqPassChange) {
            dispatch({ type : "AUTH_CHANGE_EMAIL", email : result.email });
        }
        return result;
    },

    /**
     * Logs In the User with Facebook
     * @param {Function} dispatch
     * @param {String=}  accessToken
     * @returns {Promise}
     */
    async facebookLogin(dispatch, accessToken) {
        const result = await Login.facebookLogin({ accessToken });
        if (result && result.reqPassChange) {
            dispatch({ type : "AUTH_CHANGE_EMAIL", email : result.email });
        }
        return result;
    },

    /**
     * Logs In the User with a Token
     * @param {Function} dispatch
     * @param {String}   accessToken
     * @returns {Promise}
     */
    tokenLogin(dispatch, accessToken) {
        return Login.tokenLogin({ accessToken });
    },

    /**
     * Logs In the User with a Refresh Token
     * @param {Function} dispatch
     * @returns {Promise}
     */
    refreshTokenLogin(dispatch) {
        return Login.refreshTokenLogin();
    },

    /**
     * Requests a Password Reset
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    requestReset(dispatch, data) {
        return Reset.requestReset(data);
    },

    /**
     * Verifies a Password Reset
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    verifyReset(dispatch, data) {
        return Reset.verifyReset(data);
    },

    /**
     * Resets a Password
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    resetPass(dispatch, data) {
        return Reset.resetPass(data);
    },

    /**
     * Changes the User's Password
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    changePass(dispatch, data) {
        return Reset.changePass(data);
    },

    /**
     * Fetches a single Organization
     * @param {Function} dispatch
     * @param {Number}   code
     * @returns {Promise}
     */
    async fetchOrganization(dispatch, code) {
        const data = await Join.getOne({ code });
        dispatch({ type : "AUTH_JOIN_ORGANIZATION", data });
    },

    /**
     * Accepts an Invite to Join a Organization
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    joinOrganization(dispatch, data) {
        return Join.join(data);
    },

    /**
     * Accepts an Invite to Join a Organization with Google
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    googleJoin(dispatch, data) {
        return Join.googleJoin(data);
    },

    /**
     * Accepts an Invite to Join a Organization with Microsoft
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    microsoftJoin(dispatch, data) {
        return Join.microsoftJoin(data);
    },

    /**
     * Accepts an Invite to Join a Organization with Facebook
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    facebookJoin(dispatch, data) {
        return Join.facebookJoin(data);
    },

    /**
     * Logins as an User
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @returns {Promise}
     */
    loginAs(dispatch, credentialID) {
        return Login.loginAs({ credentialID });
    },

    /**
     * Logouts the User
     * @param {Function} dispatch
     * @returns {Promise}
     */
    logout(dispatch) {
        const user = CoreAuth.getUser();
        CoreAjax.abort();
        if (user.loggedAsUser) {
            return Login.logoutAs();
        }

        const result = Login.logout();
        CoreAuth.unsetAll();
        return result;
    },

    /**
     * Deletes the Refresh Token
     * @param {Function} dispatch
     * @param {String}   accessToken
     * @returns {Promise}
     */
    deleteRefreshToken(dispatch, accessToken) {
        return Login.deleteRefreshToken({ accessToken });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "AUTH_CURRENT_USER": {
        const credential = action.credential;
        if (Utils.isEmpty(credential)) {
            return {
                ...initialState,
                isAuthenticated : false,
                isLoggedIn      : false,
            };
        }

        const access        = credential.userAccess || credential.accessName;
        const organizations = Object.entries(credential.organizations || {})
            .map(([ key, value ]) => ({ key, value }))
            .sort((a, b) => a.value.localeCompare(b.value));

        const hasUserOrganizations = organizations.length > 0;
        if (organizations.length && credential.isAdmin) {
            organizations.push({ key : "0", value : "Admin" });
        }

        return {
            ...initialState,
            isAuthenticated      : true,
            isLoggedIn           : true,
            credential           : credential,
            credentialID         : credential.credentialID,
            currentClientID      : credential.clientID,
            currentPartnerID     : credential.partnerID,
            currentUserID        : credential.userID,
            loggedAsUser         : credential.loggedAsUser,
            organizations        : organizations,
            hasOrganizations     : organizations.length > 1,
            hasUserOrganizations : hasUserOrganizations,
            currOrganization     : credential.organizations?.[credential.userID] ?? "",

            isAnyAdmin           : [ "Support", "Admin", "SuperAdmin" ].includes(access),
            isAnyUser            : [ "User", "Agent", "Supervisor", "Owner", "Partner" ].includes(access),
            isClientUser         : [ "Agent", "Supervisor", "Owner" ].includes(access),

            isSuperAdmin         : access === "SuperAdmin",
            isAdmin              : access === "Admin",
            isSupport            : access === "Support",
            isPartner            : access === "Partner",
            isOwner              : access === "Owner",
            isSupervisor         : access === "Supervisor",
            isAgent              : access === "Agent",
            isOnlyCredential     : access === "User",
        };
    }

    case "AUTH_CHANGE_EMAIL":
        return {
            ...state,
            isAuthenticated  : false,
            isLoggedIn       : false,
            credential       : {},
            clientID         : 0,
            email            : action.email,
        };

    case "AUTH_JOIN_ORGANIZATION":
        return {
            ...state,
            organization     : action.data,
        };

    case "PROFILE_UPLOAD":
        return {
            ...state,
            edition          : state.edition + 1,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
