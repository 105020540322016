import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";

// Components
import FieldsItems          from "Components/Utils/Details/FieldsItems";
import ChannelIcon          from "Components/Utils/Common/ChannelIcon";
import ColorList            from "Components/Utils/Common/ColorList";




/**
 * The Conversation Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationItem(props) {
    const { elem, collapsible, action, onAction, canEdit } = props;


    // Do the Render
    return <DetailList
        message="CONVERSATIONS_SINGULAR"
        collapsible={collapsible}
        action={action}
        onAction={onAction}
        canEdit={canEdit}
    >
        <DetailItem
            icon="channel"
            tooltip="CHANNELS_SINGULAR"
        >
            <ChannelIcon
                icon={elem.providerIcon}
                color={elem.providerColor}
                size={24}
            />
            <div className="left-space">{elem.channelName}</div>
        </DetailItem>

        <DetailItem
            icon="id"
            message={elem.number}
            tooltip="CONVERSATIONS_NUMBER"
        />
        <DetailItem
            icon="time"
            message={elem.createdDateTime}
            tooltip="GENERAL_CREATED_TIME"
        />
        <DetailItem
            isHidden={!elem.externalUrl}
            icon="link"
            message="GENERAL_VIEW_REFERRAL"
            href={elem.externalUrl}
            target="_blank"
        />

        <DetailItem
            icon="user"
            message={elem.userName}
            tooltip="USERS_SINGULAR"
        />
        <DetailItem
            icon="team"
            message={elem.teamName}
            tooltip="TEAMS_SINGULAR"
        />
        <DetailItem
            icon="language"
            message={elem.languageName}
            tooltip="LANGUAGES_SINGULAR"
        />
        <DetailItem
            icon="hotel"
            message={elem.hotelName}
            tooltip="HOTELS_SINGULAR"
        />
        <FieldsItems
            fields={elem.visibleFields}
        />
        <DetailItem
            isHidden={!elem.hasTags}
            icon="tag"
            tooltip="TAGS_SINGULAR"
        >
            <ColorList list={elem.tagList} />
        </DetailItem>

        <DetailItem
            icon="ad"
            message={elem.externalAd}
            prefix="CONVERSATIONS_EXTERNAL_AD_ID"
        />
        <DetailItem
            icon={elem.qualificationIcon}
            message={elem.qualificationName}
            textColor={elem.qualificationColor}
            tooltip="CONVERSATIONS_QUALIFICATION"
        />
        <DetailItem
            icon="status"
            message={elem.statusName}
            tooltip="GENERAL_STATUS"
        />
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationItem.propTypes = {
    elem        : PropTypes.object.isRequired,
    collapsible : PropTypes.string,
    action      : PropTypes.string,
    canEdit     : PropTypes.bool,
    onAction    : PropTypes.func,
};

export default ConversationItem;
