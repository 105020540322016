import Store                from "Dashboard/Core/Store";



// The initial State
const initialState = {
    showChatSupport   : false,
    chatSupportUnread : 0,
};



// The Actions
const actions = {
    /**
     * Sets the Show Chat Support
     * @param {Function} dispatch
     * @param {Boolean}  show
     * @returns {Void}
     */
    setShowChatSupport(dispatch, show) {
        dispatch({ type : "GLOBAL_SHOW_CHAT_SUPPORT", show });
    },

    /**
     * Sets the Chat Support Unread
     * @param {Function} dispatch
     * @param {Number}   unread
     * @returns {Void}
     */
    setChatSupportUnread(dispatch, unread) {
        dispatch({ type : "GLOBAL_CHAT_SUPPORT_UNREAD", unread });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "GLOBAL_SHOW_CHAT_SUPPORT":
        return {
            ...state,
            showChatSupport        : action.show,
        };

    case "GLOBAL_CHAT_SUPPORT_UNREAD":
        return {
            ...state,
            chatSupportUnread      : action.unread,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
