import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Utils                from "Dashboard/Utils/Utils";



// Styles
const Tabs = Styled(TabList)`
    width: 100%;
    margin-top: var(--main-gap);
    margin-bottom: 0;

    .tabs-content {
        width: 100%;
        gap: 8px;
    }
    .tab-item {
        padding: 6px 10px;
        display: flex;
    }
`;

const Tab = Styled(TabItem).attrs(({ isSelected, color, textColor }) => ({ isSelected, color, textColor }))`
    ${(props) => props.isSelected && `
        color: ${props.textColor};
        background-color: ${props.color};

        &:hover {
            color: white;
            box-shadow: inset 0 0 0 2em var(--black-color);
        }
    `}
`;



/**
 * The List Providers
 * @returns {React.ReactElement}
 */
function ListProviders() {
    const { clientID, providers, sort, filters, showSearch } = Store.useState("conversation");
    const { saveFilters } = Store.useAction("conversation");


    // Handles the Tab
    const handleTab = (action, provider) => {
        let providerName = [];
        if (!filters.providerName) {
            providerName = [ provider ];
        } else if (!filters.providerName.includes(provider)) {
            providerName = [ ...filters.providerName, provider ];
        } else {
            providerName = filters.providerName.filter((elem) => elem !== provider);
        }

        saveFilters({
            clientID : clientID,
            status   : sort.filter,
            filters  : JSON.stringify({ ...filters, providerName }),
        });
    };

    // Returns true if the Provider is selected
    const isSelected = (provider) => {
        if (!filters.providerName || !filters.providerName.length) {
            return true;
        }
        return filters.providerName.includes(provider);
    };



    // Do the Render
    if (showSearch || providers.length < 2) {
        return <React.Fragment />;
    }
    return <Tabs onAction={handleTab}>
        {providers.map(({ name, color, icon, amount }) => <Tab
            key={name}
            value={name}
            tooltip={name}
            tooltipVariant="top"
            icon={icon}
            color={color}
            textColor={Utils.getContrastColor(color)}
            isSelected={isSelected(name)}
            amount={amount}
        />)}
    </Tabs>;
}

export default ListProviders;
