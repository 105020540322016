import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Widget Header
 * @returns {React.ReactElement}
 */
function WidgetHeader() {
    const parent = Navigate.useParent(2);

    const { elem } = Store.useState("widget");
    const { data } = Store.useState("widgetState");
    const { setAction } = Store.useAction("widgetState");


    // Handles the Action
    const handleAction = (action) => {
        if (action.isView) {
            const url = `${process.env.REACT_APP_CHAT_URL}${elem.hash}`;
            Navigate.gotoUrl(url, true);
        } else {
            setAction(action);
        }
    };


    // Do the Render
    return <Header
        message={data.name || "WIDGETS_SINGULAR"}
        icon="widget"
        href={parent}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                variant={elem.needsPublish ? "primary" : "outlined"}
                action="PUBLISH"
                withMark={elem.needsPublish}
            />

            <ActionItem
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="CODE"
                />
                <ActionOption
                    action="VIEW"
                    message="WIDGETS_VIEW_TITLE"
                />
                <ActionOption
                    action="COPY"
                    message="WIDGETS_COPY_TITLE"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="WIDGETS_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

export default WidgetHeader;
