import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Profile }          from "Utils/API";



// The initial State
const initialState = {
    loading     : false,
    error       : false,
    edition     : 0,
    uploads     : 1,
    elem        : {},
    connections : [],
};



// The Actions
const actions = {
    /**
     * Fetches the current Credential's Profile
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchProfile(dispatch) {
        const data = await Profile.get();
        dispatch({ type : "PROFILE_ELEM", data });
    },

    /**
     * Edits the Credential's Profile
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editProfile(dispatch, data) {
        return Profile.edit(data);
    },

    /**
     * Uploads the Credential's Avatar
     * @param {Function} dispatch
     * @param {Object}   file
     * @returns {Promise}
     */
    async uploadProfile(dispatch, file) {
        await Profile.upload({ file });
        dispatch({ type : "PROFILE_UPLOAD" });
    },

    /**
     * Deletes the Credential's Profile
     * @param {Function} dispatch
     * @returns {Promise}
     */
    deleteProfile(dispatch) {
        return Profile.delete();
    },

    /**
     * Fetches the Progress
     * @param {Function} dispatch
     * @returns {Promise}
     */
    fetchProgress(dispatch) {
        return Profile.getProgress();
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.date = DateTime.formatLong(elem.time);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "PROFILE_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "PROFILE_ELEM":
        return {
            loading     : false,
            error       : false,
            edition     : state.edition + 1,
            elem        : action.data.elem || {},
            connections : Utils.parseList(action.data.connections, parseElem),
        };

    case "PROFILE_UPLOAD":
        return {
            ...state,
            uploads     : state.uploads + 1,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
