import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";
import useFilter            from "Dashboard/Hooks/Filter";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The User Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserFilter(props) {
    const { open, filters, clientID, partnerID, onClose, onSubmit } = props;

    const { teams, accesses, statuses } = Store.useState("user");


    // The Initial Data
    const initialData = {
        period   : 0,
        fromDate : "",
        toDate   : "",
        access   : [],
        teamID   : [],
        fullName : "",
        email    : "",
        phone    : "",
        status   : "",
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("user", open, initialData, filters, onSubmit, { userID : 0, clientID, partnerID });


    // Do the Render
    return <FilterDialog
        open={open}
        title="USERS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getSelect()}
                value={data.period}
                onChange={handlePeriod}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                onChange={handleChange}
            />
        </Columns>

        <Columns>
            <InputField
                className={!teams.length ? "columns-double" : ""}
                type="chooser"
                name="access"
                label="GENERAL_ACCESS"
                options={accesses}
                value={data.access}
                onChange={handleChange}
            />
            <InputField
                isHidden={!teams.length}
                type="chooser"
                name="teamID"
                label="TEAMS_NAME"
                options={teams}
                value={data.teamID}
                onChange={handleChange}
            />

            <InputField
                name="fullName"
                label="GENERAL_FULL_NAME"
                value={data.fullName}
                onChange={handleChange}
            />
            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                onChange={handleChange}
            />
            <InputField
                type="tel"
                name="phone"
                label="GENERAL_CELLPHONE"
                value={data.phone}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                onChange={handleChange}
            />
        </Columns>
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserFilter.propTypes = {
    open      : PropTypes.bool.isRequired,
    onClose   : PropTypes.func.isRequired,
    onSubmit  : PropTypes.func.isRequired,
    filters   : PropTypes.object,
    clientID  : PropTypes.number,
    partnerID : PropTypes.number,
};

export default UserFilter;
