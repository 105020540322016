import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    CampaignReport,
} from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    hasFlow   : false,
    labels    : [],
    values    : {},
    channels  : [],
    filters   : {
        reportType   : "campaign",
        period       : Period.LAST_30_DAYS,
        providerType : "",
        channelID    : 0,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "CAMPAIGN_REPORT_LOADING" });
    },

    /**
     * Fetches the Campaign Report List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  filters
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, filters = {}) {
        const params = { ...filters };
        Navigate.unsetParams(params);
        if (type === "CAMPAIGN") {
            params.campaignID = elemID;
        }
        const data = await CampaignReport.getAll(params);
        data.filters = filters;
        dispatch({ type : "CAMPAIGN_REPORT_LIST", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "CAMPAIGN_REPORT_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "CAMPAIGN_REPORT_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "CAMPAIGN_REPORT_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            hasFlow   : action.data.hasFlow,
            labels    : action.data.labels,
            amounts   : action.data.amounts,
            channels  : action.data.channels,
            filters   : action.data.filters,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
