import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import ViewDialog           from "Dashboard/Components/Dialogs/ViewDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import ViewField            from "Dashboard/Components/Form/ViewField";



/**
 * The Campaign Exec View Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CampaignExecView(props) {
    const { open, elem, onClose } = props;


    // Do the Render
    return <ViewDialog
        open={open}
        icon="simulate"
        title="CAMPAIGNS_EXECUTIONS_VIEW_TITLE"
        onClose={onClose}
        withSpacing
    >
        <Columns lastDouble>
            <ViewField
                label="GENERAL_CREATED_FEM"
                value={elem.createdTimeText}
            />
            <ViewField
                label="GENERAL_STATUS"
                value={elem.statusName}
                textColor={elem.statusColor}
            />
            <ViewField
                label="WIDGETS_PLATFORMS_SINGULAR"
                value={elem.providerName}
            />
            <ViewField
                label="CHANNELS_SINGULAR"
                value={elem.channelName}
            />
            <ViewField
                label="CONTACTS_SINGULAR"
                value={elem.contactName}
            />
            <ViewField
                label="CAMPAIGNS_TRIGGERS_SINGULAR"
                value={elem.triggerNameText}
            />

            <ViewField
                label="GENERAL_SEND_AT"
                value={elem.sendTimeText}
            />
            <ViewField
                isHidden={!elem.sentTime}
                label="GENERAL_SENT_AT"
                value={elem.sentTimeText}
            />
            <ViewField
                isHidden={!elem.deliveredTime}
                label="CAMPAIGNS_EXECUTIONS_DELIVERED_AT"
                value={elem.deliveredTimeText}
            />
            <ViewField
                isHidden={!elem.readTime}
                label="CAMPAIGNS_EXECUTIONS_READ_AT"
                value={elem.readTimeText}
            />
            <ViewField
                isHidden={!elem.failedError}
                label="CAMPAIGNS_EXECUTIONS_ERROR_MESSAGE"
                value={elem.failedError}
            />
        </Columns>
    </ViewDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CampaignExecView.propTypes = {
    open    : PropTypes.bool.isRequired,
    onClose : PropTypes.func.isRequired,
    elem    : PropTypes.object,
};

export default CampaignExecView;
