import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Contact }          from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Flow Sim Start Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowSimStart(props) {
    const { open, onClose, onSubmit } = props;

    const { elem, parentFlows } = Store.useState("flow");
    const { startSimulation } = Store.useAction("flowSimulation");


    // The Initial Data
    const initialData = {
        clientID           : 0,
        flowID             : 0,
        startHere          : 1,
        startFlowID        : 0,
        newContact         : 1,
        fullName           : "",
        useCurrentLanguage : 0,
        contactID          : 0,
        contactName        : "",
        initialMessage     : 1,
        message            : "",
    };


    // Initializes the Data
    React.useEffect(() => {
        setElem({
            ...initialData,
            flowID   : elem.id,
            clientID : elem.clientID,
        });
    }, [ open ]);

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("flow", initialData, startSimulation, onSubmit);

    // Load the Data
    const { loading } = useDialog("flow", open, 0, { clientID : elem.clientID });



    // Do the Render
    return <EditDialog
        open={open}
        icon="simulate"
        title="FLOWS_SIMULATION_START_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            isHidden={!parentFlows.length}
            type="toggle"
            name="startHere"
            label="FLOWS_SIMULATION_START_HERE"
            value={!!data.startHere}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.startHere}
            type="select"
            name="startFlowID"
            label="FLOWS_SIMULATION_START_FLOW"
            value={data.startFlowID}
            error={errors.startFlowID}
            options={parentFlows}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="newContact"
            label="FLOWS_SIMULATION_NEW_CONTACT"
            value={!!data.newContact}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!data.newContact}
            name="fullName"
            label="FLOWS_SIMULATION_CONTACT_NAME"
            value={data.fullName}
            error={errors.fullName}
            onChange={handleChange}
        />
        <InputField
            isHidden={!data.newContact}
            type="toggle"
            name="useCurrentLanguage"
            label="FLOWS_SIMULATION_USE_CURRENT_LANGUAGE"
            value={data.useCurrentLanguage}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!data.newContact}
            type="suggest"
            name="contactName"
            label="CONTACTS_SINGULAR"
            suggestID="contactID"
            suggestFetch={Contact.search}
            suggestParams={{ clientID : elem.clientID }}
            value={data.contactName}
            error={errors.contactID}
            onChange={handleChange}
            isRequired
        />

        <InputField
            type="toggle"
            name="initialMessage"
            label="FLOWS_SIMULATION_INITIAL_MESSAGE"
            value={!!data.initialMessage}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!data.initialMessage}
            type="textarea"
            name="message"
            label="FLOWS_SIMULATION_MESSAGE"
            value={data.message}
            error={errors.message}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowSimStart.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
};

export default FlowSimStart;
