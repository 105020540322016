import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";



/**
 * The Flow Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowHeader(props) {
    const { levels } = props;

    const { flowID, flowTab } = Navigate.useParams();
    const parent   = Navigate.useParent(levels);
    const navigate = Hooks.useGoto();

    const { openDetails } = Store.useAction("core");

    const { elem } = Store.useState("flow");
    const { totalChanges, publishErrors } = Store.useState("flowEditor");
    const { hasSimulation } = Store.useState("flowSimulation");

    const { setAction, setInPublish, setDetailsTab } = Store.useAction("flowState");


    // Handles the Action
    const handleAction = (action) => {
        if (action.isPublish && publishErrors.length) {
            setInPublish(true);
        } else if (action.isSimulate && hasSimulation) {
            if (!Navigate.isUrl("EDIT", flowTab)) {
                navigate("SETTINGS", "FLOWS", flowID, "EDIT");
            }
            setDetailsTab("simulation");
            openDetails();
        } else {
            setAction(action);
        }
    };

    // Handles the Goto Parent
    const handleGotoParent = () => {
        navigate("SETTINGS", "FLOWS", elem.parentFlowID, "EDIT");
    };


    // Variables
    const showGotoParent = Boolean(elem.parentFlowID);


    // Do the Render
    return <Header
        message={elem.name || "GENERAL_LOADING"}
        icon="flow"
        href={parent}
        subTitle={elem.statusName}
        subCircle={elem.statusColor}
    >
        <ActionList onAction={handleAction}>
            <ActionItem
                variant={elem.needsPublish ? "primary" : "outlined"}
                action="PUBLISH"
                withMark={totalChanges === 0 && elem.needsPublish}
                badge={totalChanges}
            />
            <ActionItem
                icon="more"
                message="GENERAL_ACTIONS"
            >
                <ActionOption
                    action="SIMULATE"
                    message="FLOWS_SIMULATION_START_TITLE"
                />
                <ActionOption
                    isHidden={!showGotoParent}
                    icon="flow"
                    message="FLOWS_NODES_GOTO_PARENT"
                    onClick={handleGotoParent}
                />
                <ActionOption
                    action="EDIT"
                    message="FLOWS_EDIT_TITLE"
                />
                <ActionOption
                    isHidden={!elem.isDraft}
                    action="COPY"
                    message="FLOWS_COPY_TITLE"
                />
                <ActionOption
                    isHidden={!elem.needsPublish}
                    action="CLEAR"
                />
                <ActionOption
                    action="DELETE"
                    message="FLOWS_DELETE_TITLE"
                />
            </ActionItem>
        </ActionList>
    </Header>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowHeader.propTypes = {
    levels : PropTypes.number.isRequired,
};

export default FlowHeader;
