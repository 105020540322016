import Store                from "Dashboard/Core/Store";
import { Notification }     from "Utils/API";



// The initial State
const initialState = {
    notificationsSupported : false,
    notificationGranted    : false,
    notificationDenied     : false,
};



// The Actions
const actions = {
    /**
     * Sets the Notifications Supported
     * @param {Function} dispatch
     * @returns {Void}
     */
    setNotificationsSupported(dispatch) {
        dispatch({ type : "NOTIFICATIONS_SUPPORTED" });
    },

    /**
     * Sets the Notification Granted
     * @param {Function} dispatch
     * @param {Boolean}  granted
     * @returns {Void}
     */
    setNotificationsGranted(dispatch, granted) {
        dispatch({ type : "NOTIFICATIONS_GRANTED", granted });
    },

    /**
     * Sets the Notification Denied
     * @param {Function} dispatch
     * @param {Boolean}  denied
     * @returns {Void}
     */
    setNotificationsDenied(dispatch, denied) {
        dispatch({ type : "NOTIFICATIONS_DENIED", denied });
    },


    /**
     * Adds a Credential Device
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @param {String}   playerID
     * @returns {Promise}
     */
    addDevice(dispatch, credentialID, playerID) {
        return Notification.addDevice({ credentialID, playerID });
    },

    /**
     * Removes a Credential Device
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @param {String}   playerID
     * @returns {Promise}
     */
    removeDevice(dispatch, credentialID, playerID) {
        return Notification.removeDevice({ credentialID, playerID });
    },

    /**
     * Stops asking for Notifications
     * @param {Function} dispatch
     * @param {Number}   credentialID
     * @returns {Promise}
     */
    stopAsking(dispatch, credentialID) {
        return Notification.stopAsking({ credentialID });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
    case "NOTIFICATIONS_SUPPORTED":
        return {
            ...state,
            notificationsSupported : true,
        };

    case "NOTIFICATIONS_GRANTED":
        return {
            ...state,
            notificationsGranted   : action.granted,
        };

    case "NOTIFICATIONS_DENIED":
        return {
            ...state,
            notificationsDenied    : action.denied,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
