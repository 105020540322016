import React                from "react";
import Styled               from "styled-components";

// Components
import LoginPage            from "Components/Auth/LoginPage";
import RecoverPassPage      from "Components/Auth/RecoverPassPage";
import CodePage             from "Components/Auth/CodePage";
import ResetPassPage        from "Components/Auth/ResetPassPage";
import ChangePassPage       from "Components/Auth/ChangePassPage";
import JoinPage             from "Components/Auth/JoinPage";
import AuthorizedPage       from "Components/Auth/AuthorizedPage";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import GuestRoute           from "Dashboard/Components/Route/GuestRoute";



// Styles
const Container = Styled.main`
    position: absolute;
    inset: 0;
    background-color: var(--background-color);
    overflow: hidden;
`;

const Section = Styled.section`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: var(--full-height);
    z-index: 1;
`;



/**
 * The Auth Page
 * @returns {React.ReactElement}
 */
function Auth() {
    return <Container>
        <Section>
            <Router>
                <GuestRoute
                    url="LOGIN"
                    component={LoginPage}
                    exact
                />
                <GuestRoute
                    url="PASS_RECOVER"
                    component={RecoverPassPage}
                    exact
                />
                <GuestRoute
                    url="PASS_CODE"
                    component={CodePage}
                    exact
                />
                <GuestRoute
                    url="PASS_RESET_CODE"
                    component={ResetPassPage}
                    exact
                />
                <GuestRoute
                    url="PASS_CHANGE"
                    component={ChangePassPage}
                    exact
                />
                <GuestRoute
                    url="JOIN_ORGANIZATION"
                    component={JoinPage}
                    exact
                />
                <GuestRoute
                    url="AUTHORIZED"
                    component={AuthorizedPage}
                    exact
                />
            </Router>
        </Section>
    </Container>;
}

export default Auth;
