import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Commons              from "Utils/Commons";

// Components
import ProviderButton       from "Components/Utils/Common/ProviderButton";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";
import Button               from "Dashboard/Components/Form/Button";



/**
 * The MercadoLibre Install
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MercadoLibreInstall(props) {
    const { isHidden, provider, onInstall } = props;


    // The Current State
    const [ permissionError, setPermissionError ] = React.useState(false);


    // Handles the Login
    const handleLogin = () => {
        setPermissionError(false);
        const url = Commons.createUrl("https://auth.mercadolibre.com.ar/authorization", {
            response_type : "code",
            client_id     : process.env.REACT_APP_MERCADO_LIBRE_ID,
            redirect_uri  : `${process.env.REACT_APP_HOOK}m/mercadolibre/token`,
        });
        Commons.openWindow(url, handleMessage, 700, 1000);
    };

    // Handles the Message
    const handleMessage = (event) => {
        if (typeof event.data === "object") {
            return;
        }
        try {
            const data = JSON.parse(event.data);
            if (data.error || !data.access_token) {
                setPermissionError(true);
                return;
            }
            onInstall(event.data);
        } catch (error) {
            console.error(error);
        }
    };



    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }

    return <>
        <ProviderButton
            logo={provider.logo}
            color={provider.color}
            message={provider.fullName}
        />

        <section>
            <p>{NLS.get("CHANNELS_MERCADO_LIBRE_TEXT")}</p>
            <Html
                variant="p"
                message="CHANNELS_MERCADO_LIBRE_START"
            />

            <Html
                isHidden={!permissionError}
                className="text-red"
                variant="p"
                message="CHANNELS_MERCADO_LIBRE_PERMISSION"
            />
        </section>

        <Button
            variant="outlined"
            message="CHANNELS_MERCADO_LIBRE_LOGIN"
            onClick={handleLogin}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MercadoLibreInstall.propTypes = {
    isHidden  : PropTypes.bool.isRequired,
    provider  : PropTypes.object.isRequired,
    onInstall : PropTypes.func.isRequired,
};

export default MercadoLibreInstall;
