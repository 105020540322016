import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import WidgetMain           from "./WidgetMain";
import WidgetChat           from "./WidgetChat";
import WidgetPlatforms      from "./WidgetPlatforms";
import WidgetIcon           from "./WidgetIcon";

// Dashboard
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Container = Styled.main`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: var(--page-height);
`;

const Header = Styled.header`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Content = Styled.section`
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 2;
    padding: 8px 2px 0 2px;
    overflow: auto;
`;



/**
 * The Widget Options
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WidgetOptions(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;

    const { widgetID, widgetTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { tongues } = Store.useState("widget");
    const { tongueID } = Store.useState("widgetState");
    const { setTongueID } = Store.useAction("widgetState");


    // Handles the Tab
    const handleTab = (url) => {
        navigate("SETTINGS", "WIDGETS", widgetID, url);
    };


    // Variables
    const isMain      = Navigate.isUrl("MAIN", widgetTab);
    const isChat      = Navigate.isUrl("CHAT", widgetTab);
    const isPlatforms = Navigate.isUrl("PLATFORMS", widgetTab);
    const isIcon      = Navigate.isUrl("ICON", widgetTab);


    // Do the Render
    return <Container>
        <Header>
            <TabList
                variant="lined"
                selected={widgetTab}
                onClick={handleTab}
            >
                <TabItem
                    message="GENERAL_MAIN"
                    icon="start"
                    url="MAIN"
                />
                <TabItem
                    message="WIDGETS_CHAT"
                    icon="conversation"
                    url="CHAT"
                />
                <TabItem
                    message="WIDGETS_PLATFORMS_NAME"
                    icon="channel"
                    url="PLATFORMS"
                />
                <TabItem
                    message="WIDGETS_ICON"
                    icon="bubble"
                    url="ICON"
                />
            </TabList>

            <ActionList>
                <ActionItem
                    isHidden={tongues.length < 2}
                    icon="language"
                    message="LANGUAGES_SINGULAR"
                    onClick={setTongueID}
                >
                    {tongues.map(({ key, value }) => <ActionOption
                        key={key}
                        value={key}
                        message={value}
                        icon={tongueID === key ? "checkedbox" : "checkbox"}
                    />)}
                </ActionItem>
            </ActionList>
        </Header>

        <Content>
            {isMain && <WidgetMain
                onChange={onChange}
                onColor={onColor}
                onClear={onClear}
                forceClear={forceClear}
                getText={getText}
            />}
            {isChat && <WidgetChat
                onChange={onChange}
                onColor={onColor}
                onClear={onClear}
                forceClear={forceClear}
                getText={getText}
            />}
            {isPlatforms && <WidgetPlatforms
                onChange={onChange}
                onClear={onClear}
                forceClear={forceClear}
                getText={getText}
            />}
            {isIcon && <WidgetIcon
                onChange={onChange}
                onColor={onColor}
                onClear={onClear}
                forceClear={forceClear}
                getText={getText}
            />}
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WidgetOptions.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default WidgetOptions;
