import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.header`
    box-sizing: border-box;
    position: relative;
    display: flex;
    gap: 8px;
    padding: 8px;
    padding-top: 0;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--lighter-gray);

    @media screen and (max-width: 450px) {
        .btn-content {
            display: none;
        }
    }
`;



/**
 * The Integration Actions
 * @returns {React.ReactElement}
 */
function IntegrationActions() {
    const { actions } = Store.useState("integrationEditor");
    const { hasChanges, selectedAction } = Store.useState("integrationState");

    const { copyAction, moveAction } = Store.useAction("integrationEditor");
    const { setAction } = Store.useAction("integrationState");


    // Handles the Copy
    const handleCopy = () => {
        if (hasChanges) {
            setAction("WARNING");
        } else if (selectedAction) {
            copyAction(selectedAction);
        }
    };

    // Handles the Move
    const handleMove = (direction) => {
        if (hasChanges) {
            setAction("WARNING");
        } else if (selectedAction) {
            moveAction(selectedAction, direction);
        }
    };

    // Handles the Delete
    const handleDelete = () => {
        if (selectedAction) {
            setAction("REMOVE");
        }
    };


    // Variables
    const action      = selectedAction ? Utils.getValue(actions, "id", selectedAction) : null;
    const canCopy     = Boolean(action && !action.isTrigger && !action.isUnique);
    const canMoveUp   = Boolean(action && !action.isTrigger && action.position > 2);
    const canMoveDown = Boolean(action && !action.isTrigger && action.position < actions.length);
    const canDelete   = Boolean(action);


    // Do the Render
    return <Container>
        <Button
            isHidden={!canCopy}
            variant="outlined"
            message="GENERAL_COPY"
            icon="copy"
            onClick={handleCopy}
            isSmall
        />
        <Button
            isHidden={!canMoveUp}
            variant="outlined"
            message="GENERAL_MOVE"
            icon="up"
            onClick={() => handleMove(-1)}
            isSmall
        />
        <Button
            isHidden={!canMoveDown}
            variant="outlined"
            message="GENERAL_MOVE"
            icon="down"
            onClick={() => handleMove(1)}
            isSmall
        />
        <Button
            isHidden={!canDelete}
            variant="outlined"
            message="GENERAL_DELETE"
            icon="delete"
            onClick={handleDelete}
            isSmall
        />
    </Container>;
}

export default IntegrationActions;
