import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import PersonalizeMain      from "./Options/PersonalizeMain";
import PersonalizeHeader    from "./Options/PersonalizeHeader";
import PersonalizeSearch    from "./Options/PersonalizeSearch";
import PersonalizeHome      from "./Options/PersonalizeHome";
import PersonalizeCategory  from "./Options/PersonalizeCategory";
import PersonalizeButtons   from "./Options/PersonalizeButtons";
import PersonalizeOrder     from "./Options/PersonalizeOrder";

// Dashboard
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";



// Styles
const Container = Styled(PageContainer)`
    aside {
        display: none;
    }
`;



/**
 * The Personalize Options
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PersonalizeOptions(props) {
    const { onChange, onColor, onClear, forceClear, getText } = props;


    // Do the Render
    return <Container hasTabs>
        <PageAccordion initial="main">
            <AccordionItem
                value="main"
                message="PERSONALIZE_MAIN_TITLE"
                description="PERSONALIZE_MAIN_DESC"
                withGap
            >
                <PersonalizeMain
                    onChange={onChange}
                    onClear={onClear}
                    forceClear={forceClear}
                />
            </AccordionItem>

            <AccordionItem
                value="header"
                message="PERSONALIZE_HEADER_TITLE"
                description="PERSONALIZE_HEADER_DESC"
                withGap
            >
                <PersonalizeHeader
                    onChange={onChange}
                    onColor={onColor}
                    onClear={onClear}
                    forceClear={forceClear}
                    getText={getText}
                />
            </AccordionItem>

            <AccordionItem
                value="search"
                message="PERSONALIZE_SEARCH_TITLE"
                description="PERSONALIZE_SEARCH_DESC"
                withGap
            >
                <PersonalizeSearch
                    onChange={onChange}
                    onColor={onColor}
                    onClear={onClear}
                    forceClear={forceClear}
                    getText={getText}
                />
            </AccordionItem>

            <AccordionItem
                value="home"
                message="PERSONALIZE_HOME_TITLE"
                description="PERSONALIZE_HOME_DESC"
                withGap
            >
                <PersonalizeHome
                    onChange={onChange}
                    onClear={onClear}
                    forceClear={forceClear}
                    getText={getText}
                />
            </AccordionItem>

            <AccordionItem
                value="category"
                message="PERSONALIZE_CATEGORY_TITLE"
                description="PERSONALIZE_CATEGORY_DESC"
                withGap
            >
                <PersonalizeCategory
                    onChange={onChange}
                />
            </AccordionItem>

            <AccordionItem
                value="buttons"
                message="PERSONALIZE_BUTTONS_TITLE"
                description="PERSONALIZE_BUTTONS_DESC"
                withGap
            >
                <PersonalizeButtons
                    onColor={onColor}
                    onClear={onClear}
                    forceClear={forceClear}
                />
            </AccordionItem>

            <AccordionItem
                value="order"
                message="PERSONALIZE_ORDER_TITLE"
                description="PERSONALIZE_ORDER_DESC"
                withGap
            >
                <PersonalizeOrder
                    onChange={onChange}
                    onClear={onClear}
                    forceClear={forceClear}
                    getText={getText}
                />
            </AccordionItem>
        </PageAccordion>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PersonalizeOptions.propTypes = {
    onChange   : PropTypes.func.isRequired,
    onColor    : PropTypes.func.isRequired,
    onClear    : PropTypes.func.isRequired,
    forceClear : PropTypes.func.isRequired,
    getText    : PropTypes.func.isRequired,
};

export default PersonalizeOptions;
