import React                from "react";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Hooks                from "Utils/Hooks";

// Components
import FlowHeader           from "./View/FlowHeader";
import FlowReport           from "./View/FlowReport";
import FlowConversations    from "./View/FlowConversations";
import FlowDialogs          from "./View/FlowDialogs";
import FlowEditor           from "./Editor/FlowEditor";
import AssistantRunContent  from "Components/App/Log/AssistantRunLog/AssistantRunContent";
import ConnectorLogContent  from "Components/App/Log/ConnectorLog/ConnectorLogContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Flow Page
 * @returns {React.ReactElement}
 */
function FlowPage() {
    const { flowID, flowTab } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { charging, elem } = Store.useState("flow");

    const { fetchData } = Store.useAction("flow");
    const { setInPublish } = Store.useAction("flowState");
    const { hasAssistantAI } = Store.useState("permission");


    // Load the Data
    React.useEffect(() => {
        handleLoad(flowID);
        setInPublish(false);
    }, [ flowID ]);

    // Handle the Load
    const handleLoad = async (flowID) => {
        const { elem } = await fetchData(flowID);
        if (elem && !flowTab) {
            navigate("SETTINGS", "FLOWS", flowID, "EDIT");
        }
    };

    // Handles the Tab
    const handleTab = (url) => {
        navigate("SETTINGS", "FLOWS", flowID, url);
    };


    // Variables
    const isEdit          = Navigate.isUrl("EDIT",           flowTab);
    const isReport        = Navigate.isUrl("REPORT",         flowTab);
    const isConversations = Navigate.isUrl("CONVERSATIONS",  flowTab);
    const isAssistants    = Navigate.isUrl("LOG_ASSISTANTS", flowTab);
    const isConnectors    = Navigate.isUrl("LOG_CONNECTORS", flowTab);


    // Do the Render
    return <>
        <Main withNavigation>
            <FlowHeader levels={2} />

            <Content isLoading={charging} withTabs>
                <TabList
                    variant="lined"
                    selected={flowTab}
                    onClick={handleTab}
                >
                    <TabItem
                        message="GENERAL_EDITOR"
                        icon="edit"
                        url="EDIT"
                    />
                    <TabItem
                        isHidden={elem.isDraft}
                        message="GENERAL_REPORT"
                        icon="report"
                        url="REPORT"
                    />
                    <TabItem
                        message="CONVERSATIONS_NAME"
                        icon="conversation"
                        url="CONVERSATIONS"
                    />
                    <TabItem
                        isHidden={!hasAssistantAI || !elem.hasAssistant}
                        message="ASSISTANTS_NAME"
                        icon="assistant-log"
                        url="LOG_ASSISTANTS"
                    />
                    <TabItem
                        isHidden={!elem.hasLog}
                        message="GENERAL_LOG"
                        icon="connector-log"
                        url="LOG_CONNECTORS"
                    />
                </TabList>

                {isEdit && <FlowEditor flowID={flowID} hasTabs />}
                {isReport && <FlowReport />}
                {isConversations && <FlowConversations />}
                {isAssistants && <AssistantRunContent type="FLOW" />}
                {isConnectors && <ConnectorLogContent type="FLOW" />}
            </Content>
        </Main>

        <FlowDialogs />
    </>;
}

export default FlowPage;
