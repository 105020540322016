import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import FlowActions          from "./FlowActions";
import FlowAddNode          from "./FlowAddNode";
import FlowEditorDialogs    from "./FlowEditorDialogs";
import FlowCanvas           from "../Canvas/FlowCanvas";
import FlowDetails          from "../Details/FlowDetails";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";



// Styles
const Container = Styled.section`
    display: flex;
`;

const Content = Styled.main`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (max-width: 1200px) {
        width: auto;
    }
`;

const Canvas = Styled.section.attrs(({ hasTabs }) => ({ hasTabs }))`
    height: ${(props) => props.hasTabs ? "var(--flow-height-tabs)" : "var(--flow-height)"};
    overflow: auto;
`;



/**
 * The Flow Editor
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowEditor(props) {
    const { flowID, hasTabs } = props;

    const { readying } = Store.useState("flowEditor");
    const { fetchEditor, fetchUpdate } = Store.useAction("flowEditor");
    const { initZoom, setHasChanges } = Store.useAction("flowState");


    // The References
    const containerRef = React.useRef(null);
    const timerRef     = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Remove the Auto-update
    React.useEffect(() => {
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Load the Data
    React.useEffect(() => {
        if (flowID) {
            fetchEditor(flowID);
            initZoom(flowID);
            setHasChanges(false);
            setAutoUpdate();
        }
    }, [ flowID ]);

    // Handle the Update
    React.useEffect(() => {
        fetchUpdate(flowID);
        setAutoUpdate();
    }, [ update ]);


    // Sets an auto-update
    const setAutoUpdate = () => {
        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 5 * 1000);
    };


    // Do the Render
    if (readying) {
        return <CircularLoader />;
    }
    return <Container>
        <Content>
            <FlowActions />
            <FlowAddNode />
            <Canvas ref={containerRef} hasTabs={hasTabs}>
                <FlowCanvas containerRef={containerRef} />
            </Canvas>
        </Content>

        <FlowDetails hasExternalTabs={hasTabs} />
        <FlowEditorDialogs />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowEditor.propTypes = {
    flowID  : PropTypes.number,
    hasTabs : PropTypes.bool,
};

export default FlowEditor;
