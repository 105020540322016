import React                from "react";
import Styled               from "styled-components";
import OneSignal            from "react-onesignal";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Bar = Styled.section`
    box-sizing: border-box;
    position: fixed;
    left: 50%;
    top: 8px;
    display: flex;
    align-items: center;
    gap: var(--main-gap);
    color: #fff;
    padding: 8px 16px;
    background-color: var(--black-color);
    border-radius: var(--border-radius-medium);
    white-space: nowrap;
    transform: translate(-50%);
    z-index: 2;
`;



/**
 * The Notifications Bar
 * @returns {React.ReactElement}
 */
function NotificationsBar() {
    const { loggedAsUser, credentialID, credential } = Store.useState("auth");

    const { notificationsSupported } = Store.useState("notification");
    const {
        setNotificationsSupported, setNotificationsGranted, setNotificationsDenied,
        addDevice, removeDevice, stopAsking,
    } = Store.useAction("notification");


    // The Current State
    const [ show, setShow ] = React.useState(false);


    // Initializes One Signal
    React.useEffect(() => {
        const isLocal = process.env.NODE_ENV === "development";
        if (credentialID && !isLocal) {
            if (!notificationsSupported) {
                initOneSignal();
            } else {
                setUser();
            }
        }
    }, [ credentialID ]);

    // Initializes One Signal
    const initOneSignal = async () => {
        await OneSignal.init({
            appId : process.env.REACT_APP_ONESIGNAL_ID,
            safari_web_id : process.env.REACT_APP_ONESIGNAL_SAFARI,
            serviceWorkerParam : { scope : "/push/onesignal/" },
            allowLocalhostAsSecureOrigin : true,
        });

        const isSupported = OneSignal.Notifications.isPushSupported();
        if (!isSupported) {
            return;
        }
        setNotificationsSupported(true);

        OneSignal.Notifications.addEventListener("permissionChange", (isGranted) => {
            const playerID = OneSignal.User.PushSubscription.id;
            if (playerID) {
                handleSubscription(playerID, isGranted);
            }
            handlePermission(isGranted);
        });

        OneSignal.User.PushSubscription.addEventListener("change", ({ previous, current }) => {
            if (previous.id !== current.id && current.id) {
                handleSubscription(current.id, current.optedIn);
            }
        });

        setUser();
    };

    // Sets the User
    const setUser = async () => {
        await OneSignal.login(String(credentialID));

        const permissionNative = OneSignal.Notifications.permissionNative;
        setNotificationsGranted(permissionNative === "granted");
        setNotificationsDenied(permissionNative === "denied");

        handlePermission(permissionNative === "granted");
    };


    // Handle the Subscription Change
    const handleSubscription = (playerID, isGranted) => {
        if (isGranted) {
            addDevice(credentialID, playerID);
        } else {
            removeDevice(credentialID, playerID);
        }
        setNotificationsGranted(isGranted);
        setNotificationsDenied(!isGranted);
    };

    // Handles the Permissions Change
    const handlePermission = (isGranted) => {
        setShow(!loggedAsUser && credential.askNotifications && !isGranted);
    };

    // Handles the Yes Click
    const handleYesClick = async () => {
        OneSignal.Notifications.requestPermission();
        stopAsking(credentialID);
        setShow(false);
    };

    // Handles the No Click
    const handleNoClick = async () => {
        stopAsking(credentialID);
        setShow(false);
    };


    // Do the Render
    if (!show) {
        return <React.Fragment />;
    }
    return <Bar>
        {NLS.get("NOTIFICATIONS_BAR_TEXT")}
        <Button
            variant="primary"
            message="GENERAL_YES"
            onClick={handleYesClick}
        />
        <Button
            variant="outlined"
            message="GENERAL_NO"
            onClick={handleNoClick}
        />
    </Bar>;
}

export default NotificationsBar;
