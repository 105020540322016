import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Container = Styled.div`
    position: relative;
    box-sizing: border-box;
    height: var(--report-height);
`;

const Total = Styled.div`
    position: absolute;
    top: var(--main-gap);
    right: var(--main-gap);
    font-weight: bold;
    font-size: 16px;
`;



/**
 * The Bar Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BarChart(props) {
    const { labels, amounts, colors } = props;


    // Chart Options
    const options = {
        maintainAspectRatio : false,
        interaction : {
            mode      : "index",
            intersect : false,
        },
        scales : {
            y : {
                stacked : true,
                min     : 0,
                grid    : { display : false },
                ticks   : { precision : 0 },
            },
            x : {
                stacked : true,
                grid    : { display : false },
            },
        },
        plugins : {
            tooltip : {
                cornerRadius  : 12,
                padding       : 8,
                boxPadding    : 4,
                usePointStyle : true,
            },
            legend : {
                labels : {
                    usePointStyle : true,
                },
            },
        },
    };

    // Create the Dataset
    const dataset = React.useMemo(() => {
        return {
            labels   : labels,
            datasets : Object.entries(amounts).map(([ label, data ], index) => {
                const color = colors?.[index] ?? "";
                return {
                    label           : NLS.get(label),
                    data            : data,
                    borderWidth     : 1,
                    borderRadius    : 6,
                    borderColor     : color,
                    backgroundColor : color.replace(")", ", 0.8)"),
                };
            }),
        };
    }, [ JSON.stringify(labels), JSON.stringify(amounts), JSON.stringify(colors) ]);

    // Calculate the Total
    const total = React.useMemo(() => {
        let result = 0;
        for (const data of Object.values(amounts)) {
            for (const value of data) {
                result += value;
            }
        }
        return result;
    }, [ JSON.stringify(amounts) ]);


    // Do the Render
    return <Container>
        <Total>
            {`${NLS.format("GENERAL_TOTAL")}: ${total}`}
        </Total>
        <Bar
            // @ts-ignore
            options={options}
            data={dataset}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BarChart.propTypes = {
    labels  : PropTypes.array.isRequired,
    amounts : PropTypes.object.isRequired,
    colors  : PropTypes.array,
};

export default BarChart;
