import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import FlowEditNode         from "./FlowEditNode";
import FlowSimContainer     from "../Simulation/FlowSimContainer";
import FlowSimDetails       from "../Simulation/FlowSimDetails";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Container = Styled(Details)`
    margin-left: calc(var(--main-gap) * 2 - 8px);
    padding-left: calc(var(--main-gap) * 2);
    padding-right: 0px;
    padding-bottom: 0px;
    border-left: var(--border-width) solid var(--border-color-light);
`;



/**
 * The Flow Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FlowDetails(props) {
    const { hasExternalTabs } = props;

    const { edition, nodes, publishErrors } = Store.useState("flowEditor");

    const { hasChanges, inPublish, search, detailsTab, selectedNode } = Store.useState("flowState");
    const { setAction, setDetailsTab, setSelectedNode } = Store.useAction("flowState");

    const { hasSimulation } = Store.useState("flowSimulation");

    // The Current State
    const [ results, setResults ] = React.useState([]);


    // Searches the Nodes
    React.useEffect(() => {
        if (search && search.length > 2) {
            const results = [];
            const value   = search.toLowerCase();
            for (const node of nodes) {
                if (node.name.toLowerCase().includes(value)) {
                    results.push(node);
                } else if (!node.name && NLS.get(node.message).toLowerCase().includes(value)) {
                    results.push(node);
                }
            }
            setResults(results);
        } else {
            setResults([]);
        }
    }, [ search, edition ]);


    // Handles the Select
    const handleSelect = (nodeID) => {
        if (hasChanges) {
            setAction("WARNING");
            return;
        }

        setDetailsTab("editor");
        setSelectedNode(nodeID);
        Commons.scrollToNode(nodeID);
    };

    // Returns the Error Data
    const getErrorData = (elem) => {
        const name   = elem.name || NLS.get(elem.message);
        const errors = [];
        if (elem.inputError) {
            errors.push(NLS.get("FLOWS_NODES_ERROR_INPUT"));
        }
        if (elem.cycleError) {
            errors.push(NLS.get("FLOWS_NODES_ERROR_CYCLE"));
        }
        if (elem.optionsError) {
            errors.push(NLS.pluralize("GENERAL_ERROR_OPTIONS", Utils.count(elem.errors)));
        }
        const error = NLS.format("GENERAL_ERROR_FORMAT", name, errors.join(", "));
        return { nodeID : elem.id, icon : elem.icon, error };
    };


    // Variables
    const inEditor        = detailsTab === "editor";
    const inSimulation    = detailsTab === "simulation";
    const inDetails       = detailsTab === "details";

    const hasErrors       = Boolean(inPublish && publishErrors.length > 0);
    const showErrors      = Boolean(hasErrors && inEditor);
    const hasResults      = Boolean(search && results.length > 0);
    const showResults     = Boolean(hasResults && inEditor);
    const hasEdit         = Boolean(selectedNode);
    const showEdit        = Boolean(hasEdit && inEditor);

    const showEditor      = Boolean(hasErrors || hasResults || hasEdit);
    const showContainer   = Boolean(showEditor || hasSimulation);
    const hasInternalTabs = Boolean(hasSimulation);
    const showSimulation  = Boolean(inSimulation || (!showEditor && inEditor && hasSimulation));
    const showDetails     = Boolean(hasInternalTabs && inDetails);
    const selectedTab     = showSimulation ? "simulation" : detailsTab;


    // Do the Render
    if (!showContainer) {
        return <React.Fragment />;
    }
    return <Container
        hasInternalTabs={hasInternalTabs}
        hasExternalTabs={hasExternalTabs}
        isInside
        isWide
    >
        {hasInternalTabs && <TabList
            variant="lined"
            selected={selectedTab}
            onClick={setDetailsTab}
            inDetails
        >
            <TabItem
                isHidden={!showEditor}
                message="GENERAL_EDITOR"
                icon="edit"
                value="editor"
            />
            <TabItem
                message="FLOWS_SIMULATION_NAME"
                icon="simulate"
                value="simulation"
            />
            <TabItem
                message="GENERAL_DETAILS"
                icon="info"
                value="details"
            />
        </TabList>}

        {showErrors && <DetailList
            icon="error"
            message="FLOWS_NODES_WITH_ERRORS"
            collapsible="flow-errors"
        >
            {publishErrors.map((elem) => {
                const { nodeID, error, icon } = getErrorData(elem);
                return <DetailItem
                    key={nodeID}
                    icon={icon}
                    message={error}
                    isSelected={nodeID === selectedNode}
                    onClick={() => handleSelect(nodeID)}
                />;
            })}
        </DetailList>}

        {showResults && <DetailList
            icon="search"
            message="FLOWS_NODES_FOUND_NODES"
            collapsible="flow-search"
        >
            {results.map((elem) => <DetailItem
                key={elem.nodeID}
                icon={elem.icon}
                message={elem.name || elem.message}
                isSelected={elem.nodeID === selectedNode}
                onClick={() => handleSelect(elem.nodeID)}
            />)}
        </DetailList>}

        <FlowEditNode
            open={showEdit}
        />
        <FlowSimContainer
            open={showSimulation}
            onEnd={() => setDetailsTab("editor")}
        />
        <FlowSimDetails
            open={showDetails}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FlowDetails.propTypes = {
    hasExternalTabs : PropTypes.bool,
};

export default FlowDetails;
