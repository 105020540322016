import React                from "react";
import Action               from "Dashboard/Core/Action";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import CampaignExecView     from "./CampaignExecView";
import ChannelIcon          from "Components/Utils/Common/ChannelIcon";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Campaign Exec List
 * @returns {React.ReactElement}
 */
function CampaignExecList() {
    const { fetch, load, loadFilter } = useList("campaignExec", "CAMPAIGN");

    const data = Store.useState("campaignExec");
    const { loading, list, total, filters, sort, canEdit } = data;

    const { deleteExecution } = Store.useAction("campaignExec");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Returns true if the Delete is hidden
    const hideDelete = (elemID) => {
        const sentTime = Utils.getValue(list, "clientID", elemID, "sentTime");
        return sentTime > 0;
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteExecution(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elem = Utils.getValue(list, "executionID", elemID);

    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        />
        <Table
            fetch={load}
            sort={sort}
            none="CAMPAIGNS_EXECUTIONS_NONE_AVAILABLE"
            isLoading={loading}
            hasFilter
            hasTabs
        >
            <TableHead>
                <TableHeader field="sendTime"         message="GENERAL_SEND_AT"             minWidth="150" maxWidth="150" />
                <TableHeader field="contactFirstName" message="CONTACTS_SINGULAR"           isTitle isFlex smallSpace />
                <TableHeader field="triggerName"      message="CAMPAIGNS_TRIGGERS_SINGULAR" />
                <TableHeader field="statusName"       message="GENERAL_STATUS"              minWidth="120" maxWidth="120" noSorting />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.executionID} elemID={elem.executionID}>
                    <TableCell message={elem.sendTimeText} />
                    <TableCell>
                        <ChannelIcon
                            icon={elem.providerIcon}
                            color={elem.providerColor}
                            size={24}
                        />
                        {elem.contactName}
                    </TableCell>
                    <TableCell message={elem.triggerNameText} />
                    <TableCell message={elem.statusName}      textColor={elem.statusColor} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={startAction} canEdit={canEdit}>
                <TableAction action="VIEW"   message="CAMPAIGNS_EXECUTIONS_VIEW_TITLE"   />
                <TableAction action="DELETE" message="CAMPAIGNS_EXECUTIONS_DELETE_TITLE" hide={hideDelete} />
            </TableActionList>
        </Table>

        <CampaignExecView
            open={action.isView}
            elem={elem}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="CAMPAIGNS_EXECUTIONS_DELETE_TITLE"
            message="CAMPAIGNS_EXECUTIONS_DELETE_TEXT"
            content={elem.contactName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </>;
}

export default CampaignExecList;
