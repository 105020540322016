import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";
import { Organization }     from "Utils/API";



// The initial State
const initialState = {
    loading   : false,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    total     : 0,
};



// The Actions
const actions = {
    /**
     * Fetches the current Credential's Organizations
     * @param {Function} dispatch
     * @returns {Promise}
     */
    async fetchList(dispatch) {
        dispatch({ type : "ORGANIZATION_LOADING" });
        const data = await Organization.getAll();
        dispatch({ type : "ORGANIZATION_LIST", data });
    },

    /**
     * Accepts a Organization Invite
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    acceptOrganization(dispatch, userID) {
        return Organization.accept({ userID });
    },

    /**
     * Access the given Organization
     * @param {Function} dispatch
     * @param {Number}   userID
     * @returns {Promise}
     */
    accessOrganization(dispatch, userID) {
        return Organization.access({ userID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.createdDate     = DateTime.formatDate(elem.createdTime, "dashes");
    elem.createdDateTime = DateTime.formatDate(elem.createdTime, "dashesTime");
    elem.membersText     = NLS.pluralize("ORGANIZATIONS_MEMBERS",  elem.membersCount);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "ORGANIZATION_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "ORGANIZATION_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "ORGANIZATION_LIST":
        return {
            ...state,
            loading   : false,
            error     : false,
            edition   : state.edition + 1,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : Utils.parseList(action.data.list, parseElem),
            total     : action.data.total,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
